<template>
	<div v-loading="loading" class="coupon-box">
		<el-row>
			<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<el-carousel :interval="50000" arrow="always" class="" :height=" bannerHeight + 'px' ">
					<el-carousel-item v-for="(item, index) in urls" :key="index" class="carousel-banner-item">
						<el-image :src="item.image"></el-image>
					</el-carousel-item>
				</el-carousel>
			</el-col>
		</el-row>
		<el-row class="container"> 
			<el-col :xs="24" :sm="24" :md='24' :lg="24" :xl="24" >
				<h6 class="news-h">优惠券</h6>
			</el-col>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="item in list">
				<div class="all-coupon-list">
					<div class='item'>
						<div class='left sawtooth couponStyle'>
							<div class='row1' v-if="item.use_type == 1">
								<span>￥</span>
								<span class='money'
									v-if="item.use_money&&item.use_money>0">{{item.use_money | toInt}}</span>
								<div class='condition' v-if="item.money_condition > 0">
									满{{item.money_condition | toInt}}元可用</div>
							</div>
							<div class='row1' v-if="item.use_type == 2">
								<div class='money' v-if="item.use_money&&item.use_money>0">
									{{item.use_money | toInt}}<span class="type2-span">折</span></div>
								<div class='condition' v-if="item.money_condition > 0">
									满{{item.money_condition | toInt}}元可用</div>
							</div>
						</div>
						<div class='right'>
							<div class='line1'>
								<div class='name'>
									<span class='tips tnormal' v-if="item.product_limiter == 0">全场可用</span>
									<span class='tips tnormal' v-if="item.product_limiter == 1">指定商品可用</span>
									<span class='tips tnormal' v-if="item.product_limiter == 2">指定类目可用</span>
									{{item.name}}
								</div>
							</div>
							<div class='line2'>
								<div class='date'>有效期：{{item.use_start | time}}至{{item.use_end | time}}</div>
							</div>
							<div class='line1' style="justify-content: flex-end;">
								<div class='use' @click.stop='receive(item.id)'>立即领取</div>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="(pageCount*10)" v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
					:current-page="params.page">
				</el-pagination>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	import moment from '@/utils/moment';
	import helper from '@/utils/helper';
	import {
		Coupon
	} from '@/api/coupon';
	var coupon = new Coupon();
	import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
	export default {
		data() {
			return {
				loading: 'false',
				urls: [],
				list: [],
				params: {
					page: 1,
					'per-page': 12,
					perPage:12,
					num:12
				},
				pageCount: 1, //总页数
				bannerHeight: 400, //图片父容器的高度
				screenWidth: 0, //屏幕的宽度
			}
		},
		beforeCreate() {

		},
		created() {
			this.getBanner();
			this.getList();

		},
		watch: {

		},
		filters: {
			//
			toInt(val) {
				return helper.toInt(val);
			},
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('MM-DD HH:mm');
			},
		},
		mounted: function() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		},
		methods: {
			setSize: function() {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 400 / 1920 * this.screenWidth;
			},
			//广告
			getBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'pc_couponlist_banner'
				};
				adv.list(param).then(data => {
					that.loading = false;
					that.urls = data.items;
				}).catch(() => {
					that.loading = false;
				});
			},
			//优惠券
			getList() {
				var that = this;
				var param = {
					num: 20
				};
				coupon.list(param).then(data => {
					that.list = that.list.concat(data.items);
					that.pageCount=data._meta&&data._meta.pageCount ? data._meta.pageCount : 1;
				}).catch(() => {

				});
			},
			//currentPage 改变时会触发,改变页码
			currentChange(currentPage) {
				// 当前行变更时的处理逻辑
				this.params.page = currentPage;
				this.productData = []; //请求下一页前先情况上一页数据
				this._loadData();
			},
			receive(id) {
				var that = this;
				var args = {
					coupon_id: id,
				}
				coupon.receive(args).then(res => {
					if(res.status==1){
						that.$message.success(res.msg ? res.msg : '领取成功！');
					}else{
						that.$message.error(res.msg ? res.msg : '领取失败！');
					}
				}).then(()=>{
					
				});
			}
		}
	}
</script>
<style scoped>
	@import '../styles/ccouponlist.css';

	.carousel-banner {
		height: 400px;
	}

	.carousel-banner el-carousel-item,
	.carousel-banner-item {
		height: 100%;
	}

	.carousel-banner .carousel-banner-item image {
		height: 100%;
	}

	/*优惠券*/
	.coupon-box .container{
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.item {
		display: flex;
		width: 94%;
		margin: 10px 3%;
	}

	.item .left {
		color: #fff;
		width: 32%;
		text-align: center;
		display: flex;
		align-items: center;
		background: linear-gradient(to bottom, #5bb277, #5bb277);
	}

	.normal {
		background: linear-gradient(to right, #65a1e1, #6482dd);
	}

	.unable {
		background-color: #ddd;
	}

	.left .row1 {
		width: 100%;
	}

	.item .left .money {
		font-size: 30px;
		font-weight: 700;
		color: #fff !important;
	}

	.item .left .condition {
		margin-top: 10px;
		font-size: $uni-font-size-base - 2rpx;
	}

	.item .right {
		border: 1px solid #f1f1f1;
		padding: 10px;
		width: 68%;
	}

	.item .right .line1 {
		display: flex;
	}

	.item .right .line3 {
		display: flex;
		justify-content: flex-end;
	}

	.tips {
		color: #fff;
		padding: 3px 10px;
		border-radius: 30px;
		height: 18px;
		font-size: 14px;
		margin-right: 4px;
	}

	.tnormal {
		background-color: #62a0de;
	}

	.tunable {
		background-color: #ddd;
	}

	.line1 .name {
		line-height: 20px;
	}

	.item .right {
		display: block !important;
	}

	.item .right .line2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}

	.date {
		font-size: 12px;
	}

	.use {
		color: #5bb277;
		border: 1px solid #5bb277;
		border-radius: 30px;
		padding: 5px 10px;
		margin-top: 10px;
		cursor: pointer;
	}

	.sawtooth {
		/* 相对定位，方便让before和after伪元素绝对定位偏移 */
		position: relative;
	}

	.sawtooth:before {
		content: ' ';
		width: 0;
		height: 100%;
		/* 绝对定位进行偏移 */
		position: absolute;
		top: 0;
	}

	.sawtooth:before {
		/* 圆点型的border */
		border-right: 6px dotted white;
		/* 偏移一个半径，让圆点的一半覆盖div */
		left: -3px;
	}

	.Nomore {
		text-align: center;
		padding-top: 25px;
		color: #999999;
	}

	.coupon_name {
		font-size: 12px;
	}

	.type2-text {
		font-size: 14px;
		margin-left: 3px;
	}

	/* 优惠券结束 */
</style>