<template>
	<div class="bg-fa pay-container" v-loading="loading" element-loading-text="加载中..."
		element-loading-spinner="el-icon-loading">
		<div class="container">
			<el-page-header class="container" style="padding: 10px 0;" @back="goBack" content="支付订单">
			</el-page-header>
		</div>
		<!-- <div class="pay-title">
			<span class="border-right"></span>
			<span>支付订单</span>
		</div> -->
		<div class="pay-tip" v-if="!overtiem">
			<div>订单提交成功，请尽快付款，超时自动关闭！</div>
			<div>订单号：{{item.order_no || ''}}</div>
		</div>
		<div class='lh48 pay-box' v-if="!overtiem">
			<!-- <div class="pay-title">订单支付</div> -->
			<div class="bgfff">
				<div class="time pay-last-time cr-ff0000">
					<span v-if="pay_time.hou > 0">{{ pay_time.hou }}小时</span>
					<span v-if="pay_time.min > 0">{{ pay_time.min }}分</span>
					<span v-if="pay_time.sec > 0">{{ pay_time.sec }}秒</span>
				</div>
				<div class='lh48 paytime'>支付剩余时间</div>
				<div class='lh48 mt5 text-center'><span class="price">￥{{ item.pay_amount }}</span></div>
			</div>
			<!-- <div class="method-box">
				<div class='method'><span class='payment-method'>支付方式</span></div>
				<div class="weui-cells weui-cells_after-title">
					<radio-group @change="radioChange">
						<label class="weui-cell weui-check__label" v-for="(item,index) in radioItems">
							<radio class="weui-check" :value="item.value" :checked="item.checked" />
							<div class="weui-cell__bd fs28">
								<image :src='item.img' class="img-icon mgl10" mode='widthFix'></image>
								<text>{{item.name}}<text class='money'
										v-if="item.value == 1">(可用余额{{item.money}}元)</text></text>
							</div>
							<div class="weui-cell__ft weui-cell__ft_in-radio" v-if="item.checked">
								<icon class="weui-icon-radio" type="success_no_circle" size="16"></icon>
							</div>
						</label>
					</radio-group>
				</div>
			</div> -->
			<div class='payment' @click.stop='toPay'>立即支付</div>
			<!-- 支付二维码 -->
			<el-dialog title="微信扫码支付" :visible.sync="showPayCode" width="30%" center :before-close="handleClosePay">
				<img class="pay-img" v-if="payImg" :src="payImg" />
				<span slot="footer" class="dialog-footer">
					<el-button @click="showPayCode = false">取 消</el-button>
					<el-button type="primary" @click="paySuccess">已支付</el-button>
				</span>
			</el-dialog>
			<!-- 支付二维码end -->
		</div>
		<div v-else class="over">
			<div class="overtime">
				订单超时，系统已自动取消
			</div>
			<div class='lh48 mt5'>订单号：{{ item.order_no }}</div>
			<div class='lh48 mt5'>订单金额：<span class="theme-color">￥</span><span class="price">{{ item.pay_amount }}</span>元</div>
		</div>
		<div :class="showInput ? '' : 'hide'">
			<div class='m-content'>
				<div class='input-box'>
					<input @input="code" placeholder-class="p-input" class='pass-input' type='password'
						placeholder='请输入支付密码'></input>
					<div class='forgot' @click='toSetPass'>忘记密码？找回密码</div>
				</div>
				<div class='op-bar'>
					<div class='cancel btn-item' @click='hideModal'>取消</div>
					<div class='yes btn-item' @click='payConfirm'>
						<div v-if="paying" class="loading-icon"><text class="iconfont icon-tupianzhengzaijiazai"></text>
						</div>
						<div @click.stop="toPay">确认支付</div>
					</div>
				</div>
			</div>
			<div class='maskLayer' @click='hideModal'></div>
		</div>
	</div>
</template>
<script>
	import payment from '../utils/payment';
	import {
		User
	} from '@/api/user';
	import {
		Public
	} from '@/api/public';
	var publicModel = new Public();
	var user = new User();
	export default {
		data() {
			return {
				radioItems: [{
						name: '微信支付',
						img: '/static/wechat.png',
						value: '0',
						'checked': true
					},
					{
						name: '余额支付',
						img: '/static/wallet.png',
						value: '1',
						checked: false,
						money: 0.00
					}
				],
				payMethod: 0,
				showInput: false, //支付密码输入框显示
				interval: {},
				overtiem: false, //订单是否超时
				pay_time: {},
				item: {},
				is_group: '',
				paying: false,
				entry: 1,
				id: '',
				parent_sn: '',
				loading: true,
				payImg: '',
				showPayCode: false,
				order_no:''
			}
		},
		created() {
			//入口方式 1订单列表，2提交订单
			this.entry = this.$route.query.entry ? this.$route.query.entry : 1
			//订单信息
			this.id = this.$route.query.id ? this.$route.query.id : '';
			this.parent_sn = this.$route.query.parent_sn ? this.$route.query.parent_sn : ''
			this.is_group = this.$route.query.is_group ? this.$route.query.is_group : '';
			if (this.id || this.parent_sn) {
				this.getOrderPay();
			}
			// this.config();
		},
		filters: {
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD')
			},
		},
		methods: {
			goBack(){
				this.$router.back();
			},
			//获取订单支付信息
			getOrderPay() {
				var that = this;
				if (this.id) {
					var args = {
						order_id: this.id
					}
				}
				if (this.parent_sn) {
					var args = {
						parent_sn: this.parent_sn
					}
				}
				user.getOrderPay(args).then(data => {
					that.loading = false;
					that.item = data.item;
					that.radioItems[1].money = data.item.user_money;
					var end = data.item.end_time.replace(/-/g, '/');
					var end_time = (new Date(end)).getTime() / 1000;
					that.second = parseInt(end_time - (new Date().getTime()) / 1000);
					if (that.second <= 0) {
						that.overtiem = true;
					} else {
						that.interval = setInterval(that.countDown, 1000);
					}

				}).catch(() => {

				})
			},
			//支付方式
			radioChange(e) {
				var that = this;
				this.payMethod = e.detail.value;
				var radioItems = this.radioItems;
				for (var i = 0, len = radioItems.length; i < len; ++i) {
					radioItems[i].checked = radioItems[i].value == e.detail.value;
				}
				that.radioItems = radioItems;
			},

			//点击立即支付
			toPay() {
				var that = this;
				//支付参数
				if (this.id) {
					var params = {
						order_id: this.id
					}
				}
				if (this.parent_sn) {
					var params = {
						parent_sn: this.parent_sn
					}
				}
				if (this.payMethod == 0) {
					//微信支付
					this._execPay(params);
				} else if (this.payMethod == 2) {
					this.unionPay(params);
				} else if (this.payMethod == 1) {
					//余额支付
					//传参给设置支付密码/充值后做跳转回来
					if (this.id) {
						var type = 1;
						var id = this.id;
					}
					if (this.parent_sn) {
						var type = 2;
						var id = this.parent_sn;
					}
					if (parseFloat(this.radioItems[1].money) < parseFloat(this.item.pay_amount)) {
						// #ifdef H5
						if (payment.isWechat()) {
							uni.showModal({
								title: '提示',
								content: '您的余额不足',
								confirmText: "去充值",
								success(res) {
									if (res.confirm) {
										var url = '/pages/order/rechange?type=' + type + '&id=' + id;
										that.goto(url);
									} else if (res.cancel) {
										console.log(1)
									}
								}
							})
							return false;
						} else {
							uni.showModal({
								title: '提示',
								content: '您的余额不足',
								confirmText: "确定",
								success(res) {}
							})
							return false;
						}
						// #endif
						// #ifdef MP-WEIXIN
						uni.showModal({
							title: '提示',
							content: '您的余额不足',
							confirmText: "去充值",
							success(res) {
								if (res.confirm) {
									var url = '/pages/order/rechange?type=' + type + '&id=' + id;
									that.goto(url);
								} else if (res.cancel) {
									console.log(1)
								}
							}
						})
						// #endif	
					}

					//没绑定手机号码，也没有设置支付密码
					if (this.item.set_mobile == 0 && this.item.set_pay_pwd == 0) {
						uni.showModal({
							title: '提示',
							content: '请先绑定您的手机并设置支付密码',
							success(res) {
								if (res.confirm) {
									var url = '/pages/user/bind-mobile?setPassword=1&type=' + type + '&id=' +
										id
									that.goto(url);
								} else if (res.cancel) {

								}
							}
						})
						return false;
					}
					//绑定了手机，但没有设置支付密码
					if (this.item.set_mobile == 1 && this.item.set_pay_pwd == 0) {
						uni.showModal({
							title: '提示',
							content: '请先设置支付密码',
							success(res) {
								if (res.confirm) {
									var url = '/pages/user/set-payment?type=' + type + '&id=' + id
									that.goto(url);
								} else if (res.cancel) {
									console.log(2)
								}
							}
						})
						return false;
					}
					//支付密码输入框
					this.$data.showInput = true
				}
			},
			unionPay(params) {
				var that = this;
				params.payment_code = "weixin";
				params.client = "pc";
				user.payment(params).then(data => {
					if (data.status == 1) {

					} else {
						that.$message.error(data.message ? data.message : '请求失败');
					}
				});
			},
			//微信支付
			_execPay(params) {
				var that = this;
				// #ifdef H5
				params.payment_code = "weixin";
				params.client = "pc";

				publicModel.payment(params).then(data => {
					if (data.status == 1) {
						that.payImg = data.url;
						that.order_no=data.order&&data.order.order_no ? data.order.order_no : '';
						that.showPayCode = true;
					}else{
						that.$message.error(data.errmsg ? data.errmsg : '支付失败！')
					}
				}).catch((res) => {
					that.$message.error('支付失败！')
				});
			},
			//已支付
			paySuccess(){
				this.checkedPay();
			},
			//支付查询
			checkedPay(){
				var that=this;
				var param={
					order_no:this.order_no 
				};
				publicModel.query(param).then(data => {
					if (data.status == 1) {
						//用户已支付成功
						that.showPayCode=false;
						that.$router.push({
							path: '/myorder',
							query: {
								index: '2-1'
							}
						}).catch(() => {
						
						});
					}else{
						//用户未支付成功
						that.$confirm(data.message ? data.message : '查到订单未支付，请扫码支付', '提示', {
						    confirmButtonText: '确定',
						    cancelButtonText: '取消',
						    type: 'warning'
						}).then(() => {
						    
						}).catch((res) => {
						    that.$message.error(res.message ? res.message : '查到订单未支付！')
						});
						
					}
				}).catch((res) => {
					that.$message.error('支付失败！')
				});
			},
			handleClosePay(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			//余额支付
			payConfirm() {
				var that = this;
				if (!this.$data.pay_pwd) {
					uni.showToast({
						title: '请输入支付密码',
						icon: 'none',
						duration: 2000,
					})
					return false;
				}
				//支付参数
				if (this.$data.id) {
					var params = {
						order_id: this.$data.id
					}
				}
				if (this.$data.parent_sn) {
					var params = {
						parent_sn: this.$data.parent_sn
					}
				}
				if (that.paying) {
					return
				}
				params.pay_pwd = this.$data.pay_pwd;
				that.paying = true;
				order.accountPay(params, (data) => {
					that.paying = false;
					if (data.status == 1) {
						uni.showToast({
							title: '支付成功',
							icon: 'success',
							duration: 2000,
							success: function() {
								if (that.is_group >= 1) {
									setTimeout(function() {
										uni.navigateTo({
											url: '/pages/pintuan/order?index=0'
										});
									}, 2000)
								} else {
									setTimeout(function() {
										uni.navigateTo({
											url: '/pages/order/list?index=0'
										});
									}, 2000)
								}
							}
						})
					} else {
						uni.showToast({
							title: data.msg,
							icon: 'none',
							duration: 2000,
						})
					}
				});
			},
			//关闭弹窗
			hideModal(e) {
				this.$data.showInput = false
			},
			//获取输入的支付密码
			code(e) {
				this.$data.pay_pwd = e.detail.value
			},
			toSetPass() {
				//传参给设置支付密码/充值后做跳转回来
				if (this.$data.id) {
					var type = 1;
					var id = this.$data.id;
				}
				if (this.$data.parent_sn) {
					var type = 2;
					var id = this.$data.parent_sn;
				}
				uni.navigateTo({
					url: '/pages/user/set-payment?type=' + type + '&id=' + id
				});
			},
			//支付倒计时
			countDown() {
				var second = this.second;
				this.second--;
				let countDownArr = {};
				if (this.second <= 0) {
					clearInterval(this.$data.interval);
					return false;
				}
				var obj = null;
				// 天数位
				var day = Math.floor(second / 3600 / 24);
				var dayStr = day.toString();
				if (dayStr.length == 1) dayStr = '0' + dayStr;

				// 小时位
				var hr = Math.floor((second - day * 3600 * 24) / 3600);
				var hrStr = hr.toString();
				if (hrStr.length == 1) hrStr = '0' + hrStr;

				// 分钟位
				var min = Math.floor((second - day * 3600 * 24 - hr * 3600) / 60);
				var minStr = min.toString();
				if (minStr.length == 1) minStr = '0' + minStr;

				// 秒位
				var sec = second - day * 3600 * 24 - hr * 3600 - min * 60;
				var secStr = sec.toString();
				if (secStr.length == 1) secStr = '0' + secStr;
				countDownArr = {
					day: dayStr,
					hou: hrStr,
					min: minStr,
					sec: secStr
				}
				this.pay_time = countDownArr
			}
		}
	}
</script>
<style scoped>
	@import '../styles/payment.css'
</style>