import request from '@/utils/request'
class Coupon {
    //优惠券列表
    list(params) {
        return request({
            url: '/coupon/lists',
            method: 'post',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
	//用户领取优惠券
	receive(params) {
		return request({
		    url: '/coupon/receive',
		    method: 'post',
		    data: params,
		    headers: {
		        'Content-Type': 'application/json;charset=UTF-8'
		    }
		})
	}
    
};
export {
    Coupon
};