<template>
    <div class="bg-fa">
        <el-row class="">
            <el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
            	<el-carousel :interval="50000" arrow="always" class="" :height=" bannerHeight + 'px' ">
            		<el-carousel-item v-for="(item, index) in urls" :key="index" class="carousel-banner-item">
            			<el-image :src="item.image"></el-image>
            		</el-carousel-item>
            	</el-carousel>
            </el-col>
        </el-row>
        <el-row class="container news-list bg-fa" v-if="list.length>0">
			<el-col :xs="24" :sm="24" :md='24' :lg="24" :xl="24" >
				<h6 class="news-h">新闻动态</h6>
			</el-col>
            <el-col class="list-items" :xs="24" :sm="4" :md='4' :lg="4" :xl="4" v-for="(item, index) in list" :key='index'>
                <div class="news_list_left" @click="goDetail(item)">
                    <!-- <img :src="`https://mini.binon.com.cn${item.cover}`" v-if="item.cover"> -->
					<img :src="item.originImage" v-if="item.originImage">
                    <img src="../assets/nopic.jpg" v-else />
                </div>
				<div class="item-r" @click="goDetail(item)">
				    <div>
				        <h2>{{ item.title }}</h2>
				    </div>
				    <div class="news-desc">{{ item.description }}</div>
				    <div class="info"> <span class="time am-margin-right-sm "><i class="fa fa-clock-o"></i>
				            {{ item.created_at | time }}</span>
				    </div>
				</div>
            </el-col>
        </el-row>
		<el-empty v-if="list.length==0" description="还没新闻数据哦~"></el-empty>
		<el-pagination 
		  background
		  layout="prev, pager, next"
		  :total="(pageCount*10)" v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
			:current-page="params.page">
		</el-pagination>
    </div>

</template>
<script>
import { News } from '@/api/news';
var news = new News();
import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
import moment from '@/utils/moment';
import helper from '@/utils/helper';
export default {
    data() {
        return {
            list: [],
            url: '',
			urls:[],
			pageCount:1,
			params:{
				page: 1,
				'per-page': 10,
				perPage:10,
				num:10
			},
			bannerHeight: 400, //图片父容器的高度
			screenWidth: 0, //屏幕的宽度
        }
    },
    created() {
        this.url = this.BNConfig.urlImg;
        this.getList();
		this.getBanner();
    },
    filters: {
        // 时间格式化
        time(val) {
            return moment(val * 1000).format('YYYY-MM-DD HH:mm');
        }
    },
	mounted: function() {
		this.screenWidth = window.innerWidth;
		this.setSize();
		window.onresize = () => {
			this.screenWidth = window.innerWidth;
			this.setSize();
		};
	},
    methods: {
		setSize: function() {
			// 通过屏幕宽度(图片宽度)计算高度
			this.bannerHeight = 400 / 1920 * this.screenWidth;
		},
		//广告
		getBanner() {
			var that = this;
			var param = {
				'num': 8,
				'key': 'pc_news_banner'
			};
			adv.list(param).then(data => {
				that.urls = data.items;
			}).catch(() => {
		
			});
		},
        getList() {
            var param = {};
            news.newsList(param).then(res => {
                this.list = res.items;
                this.pageCount=res._meta&&res._meta.pageCount ? res._meta.pageCount : 1;
            }).catch(() => {
            })
        },
		//currentPage 改变时会触发,改变页码
		currentChange(currentPage) {
			// 当前行变更时的处理逻辑
			this.params.page = currentPage;
			this.list = []; //请求下一页前先情况上一页数据
			this.getList();
		},
        goDetail(item) {
            this.$router.push({
                path: '/newsdetail',
                query: {
                    id: item.id,
                    name: item.name
                }
            }).catch(() => {

            });
        }
    }
}
</script>
<style scoped>
@import '../styles/news.css';
</style>