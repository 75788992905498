<template>
	<div>
		<el-row class="">
			<el-col :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<el-row class="">
					<el-carousel :interval="50000" arrow="always" :height=" bannerHeight + 'px' ">
						<el-carousel-item v-for="(item, index) in banner" :key="index">
							<el-image :src="item.image"></el-image>
						</el-carousel-item>
					</el-carousel>
				</el-row>
			</el-col>

		</el-row>
		<el-row class="container">
			<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<!-- <ul class="container nav-list">
                    <li class="col-xs-12" v-for="(item, index) in list" :key="index" @click="select(index)"
                        :class="index == activeIndex ? 'nav-active' : ''">
                        <a :class="index == activeIndex ? 'active' : ''" href="#"><i
                                class="iconfont icon-gongsixinxi1"></i>{{ item.name }}</a>
                    </li>
                </ul> -->
				<div v-if="activeIndex == 0" v-for="item in detail" id='introduction'>
					<div class="title-lable">{{ item.title || '' }}</div>
					<div class="desc">
						<div v-if="item.content" v-html="item.content"></div>
					</div>
				</div>
				<div v-if="activeIndex == 0" v-for="item in fuwu " id="fuwu">
					<div class="title-lable">{{ item.title | '' }}</div>
					<div class="desc">
						<div v-if="item.content" v-html="item.content"></div>
					</div>

				</div>
				<div v-if="activeIndex == 0" v-for="item in productIntroduction" id='jingying'>
					<div class="title-lable">{{ item.title | '' }}</div>
					<div class="desc">
						<div class="zizhi" v-if="item.content" v-html="item.content"></div>
					</div>

				</div>
				<!--  -->
			</el-col>
		</el-row>
	</div>
</template>
<script>
	import {
		News
	} from '@/api/news';
	var news = new News();
	import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
	// import moment from '@/utils/moment';
	// import helper from '@/utils/helper';
	export default {
		data() {
			return {
				list: [{
						name: '公司简介'
					},
					{
						name: '发展历程'
					},
					{
						name: '企业风采'
					},
					{
						name: '地图定位'
					}
				],
				activeIndex: 0,
				detail: [],
				fuwu: [],
				productIntroduction: [], //资质证书
				banner: [],
				type: '',
				bannerHeight: 400, //图片父容器的高度
				screenWidth: 0, //屏幕的宽度
			}
		},
		/* filters: {
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD HH:mm');
			},

		}, */
		created() {
			this.type = this.$route.query.type;
			//描点
			if(this.type){
				window.location.hash = this.type;
			}
			this.getIndex();
			this.getFuWu();
			this.getProductIntroduction();
			this.getBanner();
		},
		watch: {
			$route(to, from) {
				if (to.query.type != from.query.type) {
					this.type = to.query.type;
					//描点
					window.location.hash = this.type;
				}
			}
		},
		mounted: function() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		},
		methods: {
			setSize: function() {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 400 / 1920 * this.screenWidth;
			},
			getBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'about_banner'
				};
				adv.list(param).then(data => {
					that.banner = data.items;
				}).catch(() => {

				});
			},
			//
			getIndex() {
				var param = {
					key: 'company'
				};
				var that = this;
				news.pageIndex(param).then(data => {
					that.detail = data.items;
				}).catch(() => {

				});
			},
			//
			getFuWu() {
				var param = {
					key: 'sales'
				};
				var that = this;
				news.pageIndex(param).then(data => {
					that.fuwu = data.items;
				}).catch(() => {

				});
			},
			getProductIntroduction() {
				var param = {
					key: 'zizhi'
				};
				var that = this;
				news.pageIndex(param).then(data => {
					that.productIntroduction = data.items;
				}).catch(() => {

				});
			},
			select(index) {
				this.activeIndex = index;
			}
		}
	}
</script>
<style scoped>
	@import '../styles/about.css';
	@import '../styles/timeline.css'
</style>