<template>
	<div class="container evl-box">
		<div class="eval">
			<div class="weui-flex eval-top">
				<div class="img-box">
					<img :src="product_img" v-if="product_img"/>
				</div>
				<div class="weui-flex__item">
					<div class="eval-title">商品评分</div>
					<div class="eval-xx">
						<el-rate v-model="product_eval" @change='changeEval'></el-rate>
					</div>
				</div>
			</div>
			<div>
				<div class="weui-cells_after-title">
					<div class="weui-cell textarea-box">
						<div class="weui-cell__bd">
							<el-input @change="inputTyping"
							  type="textarea"
							  :rows="2"
							  placeholder="请输入您的评价"
							  v-model="inputVal">
							</el-input>
						</div>
					</div>
				</div>
				<div>
				    <div class="weui-uploader__bd">
				      <div class="weui-uploader__files" id="uploaderFiles">
				        <div v-for="(item,index) in files" :key='index'>
				          <div class="weui-uploader__file" @click="predivImage" :id="item">
				            <img class="weui-uploader__img" :src="item" />
				          </div>
				        </div>
				      </div>
				      <div class="weui-uploader__input-box">
				        <div class="weui-uploader__input" @click="chooseImage"></div>
				      </div>
				    </div>
				</div>
			</div>
		</div>
		<div class="eval-box">
			<div class="eval-items">
				<div class="tit">服务评价</div>
				<div class="des">满意请给5分哦!</div>
			</div>
			<div class="eval-items">
				<div class="eval-title">商品符合度</div>
				<div class="eval-xx">
					<el-rate v-model="product_taidu_eval" @change='changeEval2'></el-rate>
				</div>
			</div>
			<div class="eval-items">
				<div class="eval-title">买家服务</div>
				<div class="eval-xx">
					<el-rate v-model="shop_taidu_eval" @change='changeEval3'></el-rate>
				</div>
			</div>
			<div class="eval-items">
				<div class="eval-title">物流速度</div>
				<div class="eval-xx">
					<el-rate v-model="shopping_eval" @change='changeEval4'></el-rate>
				</div>
			</div>
		</div>
		<div class="weui-btn-area">
			<button class="weui-btn" style="padding: 10px 0;" @click='getSubmitData'>{{btnText}}</button>
		</div>
		
	</div>
</template>
<script>
	import {
		Evaluate
	} from '@/api/evaluate';
	var evaluate = new Evaluate();
	import {
		Config
	} from '../config.js';
	var imgs = [];
	var evalArr;
	export default {
		data() {
			return {
				total_on_num: 5,
				total_off_num: 0,
				pro_on_num: 5,
				pro_off_num: 0,
				shop_on_num: 5,
				shop_off_num: 0,
				wuliu_on_num: 5,
				wuliu_off_num: 0,
				select: true,
				files: [],
				product_img: "",
				inputVal: "",
				is_nickname: 1,
				onLoading: false,
				btnArray: ['提交', '正在提交...'],
				btnText: "提交",
				shopid:'',
				product_eval:0,
				product_taidu_eval:0,
				shop_taidu_eval:'',
				shopping_eval:0
			}
		},
		created() {
			imgs = [];
			this.shopid = parseInt(this.$route.query.shop_id);
			evalArr = JSON.parse(localStorage.getItem('evalArr'));
			var id = this.$route.query.id;
			for (var i = 0; i < evalArr.length; i++) {
				if (id == evalArr[i].id) {
					evalArr = evalArr[i];
					this.product_img = evalArr.sku_image;
				}
			};
		},
		methods: {
			changeEval(e){
				this.product_eval=e;
			},
			changeEval2(e){
				this.product_taidu_eval=e;
			},
			changeEval3(e){
				this.shop_taidu_eval=e;
			},
			changeEval4(e){
				this.shopping_eval=e;
			},

			chooseImage: function(e) {
				var that = this;
				var url = Config.restUrl;
				url = url.slice(0, url.length - 4);
				uni.chooseImage({
					sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function(res) {
						// 返回选定照片的本地文件路径列表，tempFilePath可以作为img标签的src属性显示图片
						that.$data.files = that.$data.files.concat(res.tempFilePaths);
						uni.uploadFile({
							url: url + '/upload/images-upload?fileparam=image&thumb=0&width=500&height=500',
							filePath: res.tempFilePaths[0],
							name: 'image',
							formData: {},
							// #ifdef  MP-WEIXIN
							header:{"Content-Type": "multipart/form-data"},
							// #endif
							success: function(res) {
								var data = JSON.parse(res.data);
								var url = data.files[0].url;
								// files_url['url'] = files_url['url'].push(data.files[0].url)
								var thumbImg;
								var img = {};
								img.url = url;
								img.thumbImg = data.files[0].thumbImg;
								imgs.push(img);
							}
						})
					}
				})
			},
			predivImage(e) {
				uni.predivImage({
					current: e.currentTarget.id, // 当前显示图片的http链接
					urls: this.$data.files // 需要预览的图片http链接列表
				})
			},
			// 获取评价信息
			inputTyping(e) {
				this.inputVal = e
			},
			getSubmitData() {
				var that = this;
				if (that.onLoading) {
					return false;
				}
				var evalData = {
					"content": this.inputVal,
					"image": imgs,
					"total_stars": this.shopping_eval,
					"des_stars": this.product_eval,
					"service_stars": this.shop_taidu_eval,
					"delivery_stars": this.shopping_eval,
					"order_sku_id": evalArr.id,
					"order_no": evalArr.order_no,
					"goods_id": evalArr.goods_id,
					"is_nickname": this.is_nickname,
					"shop_id": this.shopid
				};
				that.onLoading = true;
					this.btnText = that.btnArray[1];
				evaluate.add(evalData).then(data => {
					that.onLoading = false;
						that.btnText = that.btnArray[0];
					if (data.status == 1) {
						setTimeout(function(){
							that.$router.push({
							    path: '/myorder',
								query: {
									index:'2-1'
								}
							}).catch(() => {
							
							});
						},1500)
						that.$message.success('评价成功~');
					} else {
						that.$message.error(data.msg ? data.msg : '评价失败~');
					}
				}).catch(()=>{
					
				})
			}
		}
	}
</script>

<style>
	.evl-box{
		    border: 1px solid #eee;
		    padding: 20px;
		    margin: 20px 0;
			background-color: #f1f1f1;
	}
	.eval {
		background-color: #fff;
		padding: 10px 15px;
	}

	.eval-top {
		display: flex;
		align-items: center;
	}

	.eval-top .img-box img {
		width: 80px;
		height: 80px;
		margin-right: 10px;
		display: div
	}

	.textarea-box {
		border: 1px solid #ededed;
		margin-top: 15px;
	}

	.textarea-box textarea {
		font-size: 12px;
		width: 400px;
		border: 1px solid #ddd;
		padding: 10px;
	}

	.weui-textarea-counter {
		font-size: 12px;
	}

	.weui-btn {
		font-size: 16px;
		background-color: #ff4444;
		color: #fff;
		border-radius: 30px;
		padding: 3px 0;
	}

	.weui-uploader__img {
		width: 50px;
		height: 50px;
	}

	.weui-uploader__input-box {
		width: 50px;
		height: 50px;
	}

	.weui-uploader__bd {
		margin: 15px 0 10px;
	}

	.weui-uploader__file {
		margin-right: 8px;
		margin-bottom: 5px;
	}

	.sel-bt {
		font-size: 12px;
		padding-left: 5px;
	}

	.icon-xingxing {
		margin-right: 5px;
		color: #ff4444;
	}

	.eval-title {
		font-size: 14px;
		margin-bottom: 0px;
	}

	icon[type="success"] {
		padding: 3px;
	}



	.eval-box {
		background-color: #fff;
		padding: 20px 15px;
		margin-top: 15px;
	}

	.eval-box .tit {
		font-size: 16px;
	}

	.eval-box .tit text {
		margin-right: 5px;
	}

	.eval-box .des {
		font-size: 12px;
		color: #999;
	}

	.eval-items {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 0;
	}

	.eval-box .eval-title {
		font-size: 12px;
		color: #666;
		text-align: right;
		padding-right: 10px;
	}

	.eval-box .eval-xx .icon-xingxing {
		margin-right: 15px;
	}

	.eval-box .eval-xx .icon-xingxing:last-child {
		margin-right: 0
	}

	.icon-quan {
		font-size: 12px;
		color: #999;
		padding: 0rpx 3px 1px 3px;
		display: inline-div;
	}
	.pingjia{
		padding: 15px 10px;
	}
	.weui-btn-area{
		width: 60%;
		margin: 40px auto;
		display: flex;
		    justify-content: center;
	}
	.weui-btn-area .weui-btn{
		display: flex;
		    justify-content: center;
			    width: 50%;
			    border: none !important;
	}
</style>
