<template>
    <el-container>
        <el-main>
            <el-row class="container">
                <el-col class="refund-box" :xs="24" :sm="24" :md="16" :lg="16" :xl='16'>
                    <div class='box'>
                        <div class='title-bar blod'>商品详情</div>
                    </div>
                    <div class="weui-cells title-bar">
                        <div class="weui-cell goods-list" v-for="(item, index) in orderData.orderSku">
                            <div class="weui-cell__hd goods-img-box">
                                <img :src="item.sku_thumbImg" v-if="item.sku_thumbImg" />
                            </div>
                            <div class="weui-cell__bd goods-info">
                                <div class="goods-tit" :data-id='item.goods_id' @click.stop='toDetail'>
                                    {{ item.goods_name}}
                                </div>
                                <div class="pos-rel">
                                    <div class="goods-attr">{{ item.sku_value }}</div>
                                </div>
                                <div class="goods-price">
                                    <dvi class="numbox-r">价格：</dvi>
                                    <div class="numbox-r">{{ item.sku_sell_price_real }}</div>
                                </div>
                                <div class="goods-price">
                                    <dvi class="numbox-r">数量：</dvi>
                                    <div class="numbox-r">x{{ item.num }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='reason-box mt30'>
                        <div class='right'>
                            <div class=" blod">申请原因：</div>
                            <el-select v-model="reasonText" placeholder="请选择" @change="handleSelectChange">
                                <el-option v-for="item in reason" :key="item.value" :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class='box mt30'>
                        <div class='title-bar2 blod'>退款方式</div>
                        <el-radio-group v-model="resonCurrent" size="small" class="reason-box" @change="selectRefund">
                            <el-radio :label="item.value" v-for="item in radioItems">{{ item.name }}</el-radio>
                        </el-radio-group>
                    </div>

                    <div class='mt30 sub' @click='_refundOrder'>申请退款</div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import { User } from '@/api/user';
var user = new User();
export default {
    data() {
        return {
            radioItems: [{
                name: '原路退回',
                value: '0',
                'checked': true
            },
            {
                name: '退回账户余额',
                value: '1',
                checked: false
            }
            ],
            radioItemsValue: '',
            reason: [
                {
                    name: '不想买了',
                    value: '不想买了'
                },
                {
                    name: '质量问题',
                    value: '质量问题'
                },
                {
                    name: '卖家发错货',
                    value: '卖家发错货'
                },
                {
                    name: '七天无理由',
                    value: '七天无理由'
                },
                {
                    name: '商品与页面图片不符',
                    value: '商品与页面图片不符'
                },
                {
                    name: '发票问题',
                    value: '发票问题'
                },
                {
                    name: '其它',
                    value: '其它'
                },
            ],
            resonCurrent: '不想买了',
            reasonIndex: 0,
            reasonText: '',
            showShare: false,
            orderData: [],
            id: '',
            discount_price: 0
        }
    },
    created() {
        this.id=this.$route.query.id;
        this._loadData();
    },
    methods: {
        _loadData() {
            var args = {
                order_id: this.id
            }
            user.orderDetail(args).then(data => {
                var discount_price = Math.abs(data.discount_price);
                this.orderData = data;
                this.discount_price = discount_price;
            }).catch(() => {

            });
        },
        //选择退款原因
        handleSelectChange(e) {
            this.reasonText = e;
        },
        //
        selectRefund(e) {
            this.radioItemsValue = e;
        },
        // 申请退款
        _refundOrder(event) {
            var id = this.id;
            if(this.reasonText===''){
                this.$message.error('请选择申请原因！');
                return;
            }else{
                if(this.radioItemsValue===''){
                    this.$message.error('请选择退款方式！');
                    return;
                }
            }
            var args = {
                order_id: id,
                type: this.radioItemsValue,
                note: this.reasonText
            }
            var that = this;
            user.refundOrder(args).then(res => {
                if (res.status == 1) {
                    that.$message.success('退款成功！');
                    setTimeout(function () {
                        that.$router.push({
                            path: '/myorder',
                            query: {}
                        }).catch(() => {

                        });
                    }, 2000);
                } else {
                    var msg = res.msg ? res.msg : '系统繁忙，请重试一下~';
                    that.$message.error(msg);
                }
            }).catch(() => {

            })
        },
        //商品详情
        toDetail(e) {
            var id = this.getDataSet(e, 'id');
            var url = 'pages/product/detail?id=' + id;
            this.goto(url);
        }
    }
}
</script>
<style scoped>
@import '../styles/refund.css';

.container {
    display: flex;
    justify-content: center;
}
</style>