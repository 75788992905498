//引入vue
import Vue from 'vue'
//引入vuex插件
import Vuex from 'vuex';
//使用插件
Vue.use(Vuex);
//引入接口
import {
    loginAPI,
    logoutAPI
} from '@/api/login'
import { Cart } from '@/api/cart';
var cart =new Cart();

const store = new Vuex.Store({
    state: {
        userInfo: null, // 用户信息
        cartNum:0, //购物车数量
        hasLogin:false, // 是否已经登录
        isLoginPage:false ,//是否登录页面
		isShowHeader:false, //是否展示nav
		isShowfooter:true, //是否展示底部的 正品保障....
		showNavChild:false //首页 全部商品分类 是否展示子分类
    },
    getters: {
		// 获取网络状态
		networkStatus: state => {
			return state.networkState;
		}
    },
    mutations: {
        SET_USERINFO(state, userInfo){
            state.userInfo = userInfo;
            //使用 Web Storage 存储键值对比存储 Cookie 方式更直观，而且容量更大，它包含两种：localStorage 和 sessionStorage ：
            localStorage.setItem('loginUserInfo', JSON.stringify(userInfo))
        },
        //设置购物车数量
        setCart(state, num){
            state.cartNum = num;
            //使用 Web Storage 存储键值对比存储 Cookie 方式更直观，而且容量更大，它包含两种：localStorage 和 sessionStorage ：
            localStorage.setItem('cart', num);
        },
        //设置是否是登录页面
        setIsLoginPage(state, status){
            state.isLoginPage = status;
            localStorage.setItem('isLoginPage', status);
        },
		//设置是否是导航页面
		setShowHeader(state, status){
		    // state.currentPage = status;
			if(status=='/myorder' || status=='/cart' || status=='/payment' || status=='/orderdetail'){
				state.isShowHeader=false
			}else{
				state.isShowHeader=true
			}
		},
		//设置
		setShowNavChild(state, status){
			state.showNavChild=status;
		},
		//是否展示底部的 正品保障....
		setShowfooter(state, status){
			if(status=='/payment' || status=='/myorder' || status=='/cart'){
				state.isShowfooter=false
			}else{
				state.isShowfooter=true
			}
		}
    },
    actions: {
        // 登录
		Login({
			commit,
			dispatch
		}, userInfo) {
			return new Promise((resolve, reject) => {
				loginAPI(userInfo).then(res => {
					const data = res.data || {}
					if (!data.hasOwnProperty('companyList')) {
						commit('SET_AUTH', data)
						dispatch('GetUserInfo')
					}
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取用户信息
		GetUserInfo({
			commit,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				adminUsersReadAPI().then(response => {
					// 邮件信息 走之前的逻辑
					commit('SET_USERINFO', response.data)
					dispatch('SystemLogoAndName')
					request(response.data)
					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},
		//获取购物车数量
		GetCartNum({
			commit,
			dispatch
		},param) {
			return new Promise((resolve, reject) => {
				cart.list(param).then(response => {
					commit('setCart', response.items[1].data.length)
				}).catch(error => {
					reject(error)
				})
			})
		}
    }
})

//导出store
export default store