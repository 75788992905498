<template>
	<div>
		<el-row class="order-detail">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<el-page-header class="container" style="padding: 10px 0;" @back="goBack" content="订单详情">
				</el-page-header>
			</el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<div class="container bg-fff order-detail-box">
					<div class="order-step" v-if="detail.status != 8&&detail.status != 9">
						<el-steps :active="activeIndex" finish-status="success">
							<el-step title="拍下商品"></el-step>
							<el-step title="付款"></el-step>
							<el-step title="买家发货"></el-step>
							<el-step title="确认收货"></el-step>
							<el-step title="评价"></el-step>
						</el-steps>
					</div>
					<div class="order-status-box">
						<div class="font-weight align" style="font-size: 24px;">
							<div>订单状态：</div>
							<div v-if="detail.status == 1" class='cr-ff0000'>待支付</div>
							<div v-if="detail.status == 2 && detail.group_status == 1" class='cr-ff0000'>
								待成团
							</div>
							<div v-else-if="detail.status == 2">等待卖家发货</div>
							<div v-if="detail.status == 3 && (detail.delivery_id == 1 || detail.delivery_id == 2)">
								待核销</div>
							<div v-if="detail.status == 3 && detail.delivery_id == 0">待收货</div>
							<div v-if="detail.status == 10" class='cr-ff6600'>退款中</div>
							<div v-if="detail.status == 5" class="cr-999">已评价</div>
							<div v-if="detail.status == 6" class="cr-999">已退款</div>
							<div v-if="detail.status == 11">商家拒绝退款</div>
							<div v-if="detail.status == 8" class="cr-ff0000">已取消</div>
							<div v-if="detail.status == 9" class="cr-ff0000">交易已关闭</div>
						</div>
						<div v-if="detail.status == 1">
							<span>剩余时间：</span>
							<span class="pay-time">{{countDownHour}}</span>时
							<span class="pay-time">{{countDownMinute}}</span>分
							<span class="pay-time">{{countDownSecond}}</span>秒
						</div>
						<div class="top-btn-box" v-if="detail.status == 1">
							<div class="cancel-btn" @click.stop="_cancelOrder(detail.id)">取消订单</div>
							<div class="pay-btn" @click.stop="toPay(detail.id)">立即支付</div>
						</div>
					</div>
					<div class="">
						<div class="font-weight">订单信息</div>
						<div>
							<table class="table table-condensed">
								<tbody>
									<tr>
										<td>订单编号：{{ detail.order_no }}</td>
										<td>创建时间：{{ detail.create_time | time }}</td>
										<td>付款方式：{{ detail.payment_name }}</td>
										<td v-if="detail.payment_status == 1">付款时间：{{ detail.paytime | time }}</td>
									</tr>
								</tbody>
							</table>

						</div>
						<div class="font-weight">收货信息</div>
						<div>
							<table class="table table-condensed">
								<tbody>
									<tr>
										<td class="col-md-3">收货人：{{ detail.full_name }}</td>
										<td class="col-md-3">电话：{{ detail.tel }}</td>
										<td class="col-md-3">&nbsp;</td>
										<td class="col-md-3">&nbsp;</td>
									</tr>
									<tr>
										<td class="col-md-12" colspan="12" style='text-align:left'>
											收货地址：{{ detail.prov }}{{ detail.city }}{{ detail.area }}
											{{ detail.address }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="font-weight">商品信息</div>
						<table class="table table-bordered">
							<thead>
								<tr class="order-detal-product-top">
									<th class="col-md-1" style="display: none;"></th>
									<th class="col-md-5" style="text-align: left;">商品信息</th>
									<th class="col-md-1">单价</th>
									<th class="col-md-1">数量</th>
									<th class="col-md-1">实付款</th>
									<th class="col-md-2">订单状态</th>
									<th class="col-md-1">操作</th>
								</tr>
							</thead>

						</table>
						<div class="pro-item">
							<table class="table">
								<thead>
									<tr>
										<th class="col-12 border-top-solid" colspan="12" style='text-align:left'>
											<span class="pro-order-date"
												v-if="detail.create_time">{{ detail.create_time | time }}</span>
											<span class="pro-order-no">订单号：{{ detail.order_no }}</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in detail.orderSku">
										<th scope="row" class="col-md-1" style="display: none;">
										</th>
										<td class="col-md-5">
											<div class="display cursor" @click="toDetail(item)">
												<div class="product-img">
													<img :src="item.sku_thumbImg" />
												</div>
												<div class="product-name">
													<span v-if="item.is_future&&item.is_future==1" style="color: #ff0000;">【预售】</span>
													{{ item.goods_name }}
												</div>
											</div>
										</td>
										<td class="col-md-1">￥{{ item.sku_sell_price_real }}</td>
										<td class="col-md-1">{{ item.num }}</td>
										<td class="col-md-1">￥{{ parseFloat(item.sku_sell_price_real) * item.num }}</td>
										<!-- 订单多个商品时 -->
										<td class="col-md-2" :class="detail.orderSku.length > 1 ? 'border-left' : ''"
											v-if="detail.orderSku.length > 1 && index == 0"
											:rowspan="detail.orderSku.length">
											<div v-if="detail.status == 1" class='cr-ff0000'>待支付</div>
											<div v-if="detail.status == 2 && detail.group_status == 1"
												class='cr-ff0000'>
												待成团
											</div>
											<div v-else-if="detail.status == 2">等待卖家发货</div>
											<div
												v-if="detail.status == 3 && (detail.delivery_id == 1 || detail.delivery_id == 2)">
												待核销</div>
											<div v-if="detail.status == 3 && detail.delivery_id == 0">待收货</div>
											<div v-if="detail.status == 10" class='cr-ff6600'>退款中</div>
											<div v-if="detail.status == 6" class="cr-999">已退款</div>
											<div v-if="detail.status == 11">商家拒绝退款</div>
											<div v-if="detail.status == 8" class="cr-999">已取消</div>
											<div v-if="detail.status == 9" class="cr-999">交易已关闭</div>
											<div class="look" v-if="detail.status == 3"
												@click.stop="toLogic(detail.id)">查看物流</div>
										</td>
										<td class="col-md-1 handle-box" v-if="detail.orderSku.length > 1 && index == 0"
											:rowspan="detail.orderSku.length">
											<div v-if="detail.status == 1" class="cr-666"
												@click.stop="_cancelOrder(detail.id)">取消订单<br /></div>
											<div v-if="detail.status == 1" class="cr-sucess"
												@click.stop="toPay(detail.id)">支付订单<br /></div>
											<div @click.stop="toRefundOrder"
												v-if="detail.type != 1 && detail.payment_status == 1 && (detail.status == 2)">
												申请退款<br /></div>
											<div @click="_confirmOrder(detail.id)"
												v-if="(detail.status == 3 && detail.delivery_id == 0) || (detail.status == 11 && detail.delivery_status == 1 && order.delivery_id == 0)">
												确认收货<br /></div>
											<div @click="toEvaluate(detail.id,detail.shop_id)"
												v-if="detail.status == 4">评价</div>
										</td>
										<!-- 订单只有一个商品时 -->
										<td class="col-md-2" v-if="detail.orderSku.length == 1">
											<div v-if="detail.status == 1" class='cr-ff0000'>待支付</div>
											<div v-if="detail.status == 2 && detail.group_status == 1"
												class='cr-ff0000'>
												待成团
											</div>
											<div v-else-if="detail.status == 2">等待卖家发货</div>
											<div
												v-if="detail.status == 3 && (detail.delivery_id == 1 || detail.delivery_id == 2)">
												待核销</div>
											<div v-if="detail.status == 3 && detail.delivery_id == 0">待收货</div>
											<div v-if="detail.status == 5" class="cr-999">已评价</div>
											<div v-if="detail.status == 6" class="cr-999">已退款</div>
											<div v-if="detail.status == 10" class='cr-ff6600'>退款中</div>
											<div v-if="detail.status == 11">商家拒绝退款</div>
											<div v-if="detail.status == 8" class="cr-999">已取消</div>
											<div v-if="detail.status == 9" class="cr-999">交易已关闭</div>
											<div class="look" v-if="detail.status == 3"
												@click.stop="toLogic(detail.id)">
												查看物流
											</div>
										</td>
										<td class="col-md-1 handle-box" v-if="detail.orderSku.length == 1">
											<div v-if="detail.status == 1" class="cr-666"
												@click.stop="_cancelOrder(detail.id)">取消订单<br /></div>
											<div @click.stop="toPay(detail.id)" v-if="detail.status == 1"
												class="cr-sucess">支付订单<br /></div>
											<div @click.stop="toRefundOrder"
												v-if="detail.type != 1 && detail.payment_status == 1 && (detail.status == 2)">
												申请退款<br /></div>
											<div @click="_confirmOrder(detail.id)"
												v-if="(detail.status == 3 && detail.delivery_id == 0) || (detail.status == 11 && detail.delivery_status == 1 && order.delivery_id == 0)">
												确认收货<br /></div>
											<div @click="toEvaluate(detail.id,detail.shop_id)"
												v-if="detail.status == 4">评价</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="bottom-price-box">
							<div>
								<span>商品总价：</span>
								<span>￥{{ detail.sku_price_real }}</span>
							</div>
							<div>
								<span>运费：</span>
								<span>￥{{ detail.delivery_price_real }}</span>
							</div>
							<div>
								<span>优惠券：</span>
								<span>￥{{ detail.coupons_price }}</span>
							</div>
							<div class="font-weight fs-18" v-if="detail.payment_status == 0">
								<span>应付款：</span>
								<span class="error">￥{{ detail.pay_amount }}</span>
							</div>
							<div class="font-weight fs-18" v-else>
								<span>实付款：</span>
								<span class="error">￥{{ detail.pay_amount }}</span>
							</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	import {
		User
	} from '@/api/user';
	var user = new User();
	var evaluate = new Evaluate();
	import {
		Evaluate
	} from '@/api/evaluate.js';
	import moment from '@/utils/moment';
	import helper from '@/utils/helper';
	import {
		Message,
		MessageBox
	} from 'element-ui';
	export default {
		data() {
			return {
				id: '',
				detail: {},
				activeIndex: 1,
				countDownHour: 0,
				countDownMinute: 0,
				countDownSecond: 0,
				fromRouter:''
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getDetail();
		},
		filters: {
			//
			toInt(val) {
				return helper.toInt(val);
			},
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD HH:mm');
			},
		},
		methods: {
			goBack(){
				this.$router.back();
			},
			getDetail() {
				var that = this;
				var param = {
					order_id: this.id
				};
				user.orderDetail(param).then(data => {
					that.detail = data;
					if (data.status == 1) {
						that.counTtime(data.create_time, data.id)
					};
					if (that.detail.status == 2) {
						that.activeIndex = 2
					} else {
						if (that.detail.status == 3) {
							if (that.detail.delivery_id == 0 || that.detail.status == 11 && that.detail
								.delivery_status == 1 && that.detail.delivery_id == 0) {
								that.activeIndex = 3
							} else {
								that.activeIndex = 2
							}
						} else {
							if (that.detail.status == 4) {
								that.activeIndex = 4
							} else if (that.detail.status == 5) {
								that.activeIndex = 5
							}
						}
					}
				}).catch(() => {

				});
			},
			//倒计时
			counTtime(create_time, id, type) {
				var that = this;
				var interval = setInterval(function() {
					var endtime = ';'
					var that = this;
					if (type == 'group') {
						endtime = create_time;
					} else {
						endtime = (parseInt(create_time) + 2 * 3600);
					}
					var nowtime = Date.parse(new Date()) / 1000
					var totalSecond = endtime - nowtime;
					var second = totalSecond;

					// 天数位
					var day = Math.floor(second / 3600 / 24);
					var dayStr = day.toString();
					if (dayStr.length == 1) dayStr = '0' + dayStr;

					// 小时位
					var hr = Math.floor((second - day * 3600 * 24) / 3600);
					var hrStr = hr.toString();
					if (hrStr.length == 1) hrStr = '0' + hrStr;

					// 分钟位
					var min = Math.floor((second - day * 3600 * 24 - hr * 3600) / 60);
					var minStr = min.toString();
					if (minStr.length == 1) minStr = '0' + minStr;

					// 秒位
					var sec = second - day * 3600 * 24 - hr * 3600 - min * 60;
					var secStr = sec.toString();
					if (secStr.length == 1) secStr = '0' + secStr;
					this.countDownDay = dayStr;
					this.countDownHour = hrStr;
					this.countDownMinute = minStr;
					this.countDownSecond = secStr;
					totalSecond--;
					if (type != 'group') {
						if (totalSecond < 0) {
							clearInterval(interval);
							var cancelParam = {
								order_id: id
							}
							user.scancelOrder(cancelParam).then(res => {
								that.detail.status = 9
							}).catch(() => {

							})
						}
					}
					//订单关闭


				}.bind(this), 1000);

			},
			//
			toLogic(id) {
				this.$router.push({
					path: '/logistics',
					query: {
						id: id
					}
				}).catch(() => {

				});
			},
			// 取消订单
			_cancelOrder(id) {
				var that = this;
				MessageBox.confirm('订单还未付款，确定要取消么？', '提示', {
					confirmButtonText: '取消订单',
					cancelButtonText: '考虑一下',
					type: 'warning'
				}).then(() => {
					//取消订单
					var param = {
						order_id: id,
					};
					user.cancelOrder(param).then(res => {
						if (res.status == 1) {
							that.$message.success("取消订单成功！");
							that.detail.status = 8;
						} else {
							var msg = res.msg ? res.msg : '取消订单失败！'
							that.$message.error(msg);
						}
					}).catch(() => {

					})
				}).catch(() => {
					//考虑一下
				});
			},
			//去支付
			toPay(id) {
				this.$router.push({
					path: '/payment',
					query: {
						id: id
					}
				}).catch(() => {

				});
			},
			toDetail(item) {
				this.$router.push({
					path: '/prodetail',
					query: {
						id: item.goods_id,
						name: item.goods_name
					}
				}).catch(() => {

				});
			},
			// 申请退款
			toRefundOrder() {
				this.$router.push({
					path: '/refund',
					query: {
						id: this.id
					}
				}).catch((res) => {
					this.$message.error(res.message ? res.message : '申请退款失败~');
				});
			},
			// 确认收货
			_confirmOrder(id) {
				var that = this;
				var id = id;
				MessageBox.confirm('你是否收到该订单商品？', '提示', {
					confirmButtonText: '已收货',
					cancelButtonText: '未收货',
					type: 'warning'
				}).then(() => {
					var param = {
						order_id: id
					}
					user.confirmOrder(param).then(res => {
						if (res.status == 1) {
							that.$message.success('确认收货成功!');
							that.detail.status = 4;
							that.activeIndex = 4;
						} else {
							that.$message.error('系统繁忙，请重试一下~!');
						}
					}).catch(() => {

					})
				}).catch(() => {

				})
			},
			toEvaluate(order_id, shop_id) {
				var that = this;
				var param = {
					order_id: order_id
				};
				evaluate.productList(param).then(data => {
					if (data.item && data.item.length > 0) {
						that.confirmEvaluate(data.item[0].id, data.item[0].shop_id);
						localStorage.setItem('evalArr', JSON.stringify(data.item));
					} else {
						that.$message.error('获取数据失败！')
					};
				}).catch((res) => {
					that.$message.error(res.message ? res.message : '获取数据失败！')
				})
			},
			//评价具体的商品
			confirmEvaluate(id, shop_id) {
				this.$router.push({
					path: '/evaluate',
					query: {
						id: id,
						shop_id: shop_id
					}
				}).catch(() => {

				});
			},
		}
	}
</script>
<style scoped>
	@import '../styles/order-detail.css'
</style>