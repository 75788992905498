import request from '@/utils/request'
class Public{
	//注册
	register(param) {
		return request({
			url: '/site/register',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//短信登录
	smsLogin(param,callback) {
		http.post('auth/sms-login',param).then(data => {
			callback && callback(data);
		});
	}
	//获取短信验证码
	sms(param) {
		return request({
			url: '/sms/sms',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//账号密码登录
	loginBypdw(param) {
		return request({
			url: '/site/login',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//支付
	payment(param) {
		return request({
			url: '/pay/code',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//支付查询
	query(param) {
		return request({
			url: '/order/query',
			method: 'get',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//首页导航
	getNav(param) {
		return request({
			url: '/nav/navitems',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	
};

export { Public };