import request from '@/utils/request'
import {
	Config
} from '../../src/config';
class User {
	//个人信息
	info(param) {
		return request({
			url: '/member/info?expand=memberAuth',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//个人信息编辑
	edit(param) {
		return request({
			url: '/member/edit',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//收货地址
	addressList(param) {
		return request({
			url: '/address/index',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*获得用户默认地址*/
	defaultAddr(param) {
		return request({
			url: '/address/get-default',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//设为默认地址
	default (param) {
		return request({
			url: '/address/set-default',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//新建地址
	create(param) {
		return request({
			url: '/address/create',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*更新保存地址*/
	submitAddress(param) {
		return request({
			url: '/address/update',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*删除地址*/
	delAddress(param, callback) {
		return request({
			url: '/address/delete',
			method: 'get',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//我的优惠券
	coupon(param) {
		return request({
			url: '/member/coupons?page=1',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//我的订单
	all(param) {
		return request({
			url: '/order/all',
			method: 'get',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//取消订单
	cancelOrder(param) {
		return request({
			url: '/order/cancel',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//订单详情
	orderDetail(param) {
		return request({
			url: '/order/info?expand=orderPick,shop',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*系统关闭订单*/
	scancelOrder(param) {
		return request({
			url: '/order/scancel',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//查看物流
	getLogisticsDetail(param) {
		return request({
			url: '/order/shipping',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*申请退款。收货前*/
	refundOrder(param) {
		return request({
			url: '/order/apply-refund',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*下订单*/
	doOrder(param) {
		var that = this;
		return request({
			url: '/cart/checkout',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	// 获取订单支付信息
	getOrderPay(param, callback) {
		return request({
			url: '/order/pay',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	/*加入购物车*/
	add(param) {
		return request({
			url: '/cart/add',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	confirm(param) {
		return request({
			url: '/cart/confirm',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//确认收货
	confirmOrder(param, callback) {
		return request({
			url: '/order/confirm',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//支付
	payment(param, callback) {
		return request({
			url: '/pay/code',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}

};

export {
	User
};