import request from '@/utils/request'
class Collection {
	/// 收藏商品
	add(param) {
		return request({
		    url: '/collection/add',
		    method: 'post',
		    data: param,
		    headers: {
		        'Content-Type': 'application/json;charset=UTF-8'
		    }
		})
	}
	// 取消收藏
	del(param) {
		return request({
		    url: '/collection/del',
		    method: 'post',
		    data: param,
		    headers: {
		        'Content-Type': 'application/json;charset=UTF-8'
		    }
		})
	}
	// 收藏列表数据
	getCollectionLists(param, callback) {
		return request({
		    url: 'collection/lists?expand=product&page=' + param.page,param,
		    method: 'post',
		    data: param,
		    headers: {
		        'Content-Type': 'application/json;charset=UTF-8'
		    }
		})
	}
};
export {
	Collection
};
