<template>
    <el-container>
        <el-row class="container" guter="8">
            <el-col :xs="24" :sm="24" :md="24" :xl="24" :lg="24">
				<div>
					<el-page-header style="padding: 40px 0 0;" @back="goBack" content="物流详情">
					</el-page-header>
				</div>
                <div class="logistics-box bg-fff" v-if="status == 1">
                    <div class="logi-top">
                        <div class="orderId">订单编号：{{ orderCode }}<a class="copy-btn" v-clipboard:copy="orderCode" v-clipboard:success="onCopy" v-clipboard:error="onError">复制单号</a></div>
                        <div class="logi-top-left">
                            <div>快递名称：{{ shipping_name }}</div>
                        </div>
                    </div>
                    <el-timeline class="logi-status-list" :reverse="reverse">
                        <el-timeline-item :class="index == 0 ? 'active-color' : ''"
                            v-for="(activity, index) in tracesData" :key="index" :timestamp="activity.time" size="large"
                            placement="bottom" :color="index == 0 ? '#409EFF' : ''">
                            <span :class="index == 0 ? 'active-color' : ''" v-if="api == 'kuaidi100'">
                                {{ activity.context }}&nbsp;&nbsp;&nbsp;{{ activity.AcceptTime[0] }}
                                {{ activity.AcceptTime[1] }}
                            </span>
                            <span :class="index == 0 ? 'active-color' : ''" v-if="api == 'kuaidiniao'">
                                {{ activity.AcceptStation }}&nbsp;&nbsp;&nbsp;{{ activity.AcceptTime[0] }}
                                {{ activity.AcceptTime[1] }}
                            </span>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <el-empty :description="msg"></el-empty>
                </div>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import Lockr from 'lockr'
import { User } from '@/api/user'
var user = new User();
export default {
    data() {
        return {
            list: [],
            path: 'index.html',
            reverse: false,
            id: '',
            api: '', //
            tracesData: [],
            shipping_name: '',
            logisticCode: '',
            orderCode: '',
            status: 0,
			msg:'暂无物流信息~'
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.getLogistics();
    },
    methods: {
		goBack(){
			this.$router.back();
		},
        //
        getLogistics() {
            var that = this;
            var param = {
                order_id: this.id
            };
            user.getLogisticsDetail(param).then(data => {
                that.status = data.status;
                if (data.status == 1) {
                    that.api = data.api;
                    if (data.api == 'kuaidiniao') {
                        var traces = data.items.Traces;
                        var timeArr = [];
                        for (let i = 0; i < traces.length; i++) {
                            timeArr = traces[i].AcceptTime.split(' ')
                            traces[i].AcceptTime = timeArr;
                        }
                        that.tracesData = traces;
                        that.shipping_name = data.shipping_name;
                        that.logisticCode = data.items.LogisticCode;
                        that.orderCode = data.items.OrderCode;
                    } else if (data.api == 'kuaidi100') {
                        var traces = data.items.data;
                        var timeArr = [];
                        for (let i = 0; i < traces.length; i++) {
                            timeArr = traces[i].time.split(' ')
                            traces[i].AcceptTime = timeArr;
                        }
                        that.tracesData = traces;
                        that.shipping_name = data.shipping_name;
                        that.logisticCode = data.items.nu;
                        that.orderCode = data.order_no;
                    }
                } else {
					this.msg=data.msg;
                    this.$message.error(data.msg);
                }
            }).catch(() => {

            });
        },
        onCopy(e) { 　　 // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {　　 // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
    }
}
</script>
<style scoped>
@import '../styles/logistics.css';
</style>