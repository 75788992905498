import {Base} from "../utils/base"
class Wx extends Base{
	constructor() {
		super();
	}
	//支付配置
	sdkConfig(param) {
		return request({
            url: '/third-party/sign',
            method: 'post',
            data: param,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
	}
	//通过订单信息获取支付信息
	payCreate(param,callback) {
		var params = {
		  url:'common/pay/create',
		  data:param,
		  method:'post',
		  sCallback:function(data){
			callback && callback(data);
		  }
		}
		this.request(params);
	}
	
};

export { Wx };