<template>
	<el-container>
		<el-row class="container all-category" v-loading="loading">
			<!-- 头部筛选 -->
			<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<div class="sort-box">
					<div class="sort-box-top"">
                        <input class=" input" type="text" placeholder="请输入商品名称搜索" v-model="inputVal"
						@keypress="search">
						<a href="#" @click="search">
							<el-button class="search-btn-box" type="success" icon="el-icon-search">搜索</el-button>
						</a>
					</div>
				</div>
			</el-col> 
			<!-- 头部筛选end -->
			<el-col :xs="12" :sm="12" :md='6' :lg="6" :xl="6" v-for="(item, index) in productData" :key="index">
				<!--更多产品 -->
				<div class="why-box">
					<router-link :to="`/prodetail?id=${item.product_id}`">
						<div class="tc icon-img ">
							<img :src="item.image[0].thumbImg" v-if="item.image && item.image[0].thumbImg" />
							<img src="../assets/no-pic.png" v-else />
							<div class="">
								<h3 class="text-left">
									<span class="pre-sale-product" v-if="item.is_future&&item.is_future==1">预售</span>
									{{ item.name }}
								</h3>
								<div class="text-left width-100 product-price">
									<span class="fs-18 price-txt">
										<span class="fs-14">￥</span>
										{{ item.min_price }}
									</span>
									<span class="iconfont icon-gouwu"></span>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</el-col>
			<el-col class="pagination-list" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<el-pagination background layout="prev, pager, next" :total="(pageCount * 10)"
					v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
					:current-page="params.page">
				</el-pagination>
			</el-col>
		</el-row>
	</el-container>
</template>
<script>
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	export default {
		data() {
			return {
				productData: [], //商品列表
				params: {
					page: 1,
					'per-page': 12,
					num: 12,
					is_top: 1 //推荐商品
				},
				val: '',
				sort: '',
				moreSort: true, // 综合排序
				saleSort: false, //销量排序
				priceSort: false, //价格排序
				isUp: true, // 正序
				col: 2, //商品每行列数
				page: 1,
				searchHistory: [], //搜索记录
				inputShowed: false,
				inputVal: '', //搜索内容
				type_id: '',
				pageCount: 1, //总页数
				categoryData: [{
					cat_name: '全部',
					category_id: ''
				}],
				categoryDataIndex: 0,
				hasChooseList: [],
				loading: 'false'
			}
		},
		beforeCreate() {

		},
		created() {
			this._loadData();
		},
		watch: {

		},
		methods: {
			/*加载商品数据*/
			_loadData(param = {}) {
				var that = this;
				var params = JSON.stringify(param) === '{}' ? this.params : param;
				product.productList(params).then(data => {
					that.loading = false;
					var products = data.items;
					that.productData = that.productData.concat(products);
					that.pageCount = data._meta.pageCount
				}).catch(() => {
					that.loading = false;
				})
			},
			//currentPage 改变时会触发,改变页码
			currentChange(currentPage) {
				// 当前行变更时的处理逻辑
				this.params.page = currentPage;
				this.productData = []; //请求下一页前先情况上一页数据
				this._loadData();
			},
			//搜索
			search() {
				var param = {
					name: this.inputVal,
					...this.params
				};
				this.productData = [];
				this.loading = true;
				this._loadData(param);
			}
		}
	}
</script>
<style scoped>
	@import '../styles/allproduct.css';
	.pre-sale-product {
		background-color: #ff0000;
		color: #fff;
		margin-right: 5px;
		font-size: 14px;
		padding: 1px 4px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
</style>