<template>
	<div v-loading='loading'>
		<!-- 轮播图 -->
		<el-row class="">
			<el-carousel :interval="50000" arrow="always" class="" :height=" bannerHeight + 'px' ">
				<el-carousel-item v-for="(item, index) in urls" :key="index" class="carousel-banner-item">
					<el-image :src="item.image"></el-image>
				</el-carousel-item>
			</el-carousel>
		</el-row>
		<!-- 轮播图end -->
		<!-- 商品分类 -->
		<div class="bg-fa pc-nav-list">
			<el-row :gutter="10" class="container">
				<el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4" v-for="(item,index) in cateList">
					<div class="nav-item" @click.stop="toCate(item,index)" :style="{'backgroundColor':item.bgcolor}">

						<img :src='item.image' v-if="item.image" />
						<div class="iconfont icon-nopicture" v-else />
						<div>
							<div class="nav-lable">{{item.cat_name}}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 商品分类end -->
		<!-- 主推爆款 -->
		<div class="bg-fff more-product hot-product">
			<el-row :gutter="25" class="container">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="more-product-title">
					<span class="iconfont icon-shuxian"></span>
					<h1>主推爆款</h1>
					<span class="more-product-title-lable">Hot sale</span>
				</el-col>
				<!-- 左侧广告位 -->
				<!-- <el-col  :xs="12" :sm="12" :md="5" :lg="5" :xl="5" style="margin: 10px 0;width: 300px;height:700px;">
					<el-carousel :interval="50000" arrow="always" :indicator-position='none' style="height: 700px;">
						<el-carousel-item  style="height: 700px;">
							<el-image src="https://17135106.s61i.faimallusr.com/2/AD0IguyVCBACGAAgy9O33AUozo2lHDDpATiuBA.jpg.webp"></el-image>
						</el-carousel-item>
					</el-carousel>
				</el-col> -->
				<!-- 左侧广告位end -->
				<el-col :xs="12" :sm="12" :md="5" :lg="5" :xl="5" v-for="(item, index) in list" :key="index"
					class="hot-product-list product-item">
					<router-link :to="`/prodetail?id=${item.product_id}`" target="_blank">
						<div class="item-box ">
							<div class="tc icon-img ">
								<img :src="item.image[0].thumbImg">
							</div>
							<h3 class="text-left pro-txt">
								<span class="pre-sale-product" v-if="item.is_future&&item.is_future==1">预售</span>
								{{ item.name }}
							</h3>
							<div class="text-left width-100 product-price">
								<span class="fs-18 price-txt">
									<span class="fs-14">￥</span>
									{{ item.min_price }}
								</span>
								<span class="iconfont icon-31gouwuche"></span>
							</div>
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<!-- 主推爆款end -->
		<!-- 新品上市 -->
		<div class="bg-fa more-product new-product">
			<el-row :gutter="10" class="container">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="more-product-title">
					<span class="iconfont icon-shuxian"></span>
					<h1>新品上市</h1>
					<span class="more-product-title-lable">New product launch</span>
				</el-col>
				<el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" class="carousel-box">
					<el-carousel :interval="50000" arrow="always" height="340px">
						<el-carousel-item v-for="(item, index) in newProductBanner" :key="index" height="340px">
							<el-image :src="item.image"></el-image>
						</el-carousel-item>
					</el-carousel>
				</el-col>
				<el-col :xs="12" :sm="12" :md="5" :lg="5" :xl="5" v-for="(item, index) in newProduct" :key="index"
					class="product-item">
					<router-link :to="`/prodetail?id=${item.product_id}`" target="_blank">
						<div class="item-box ">
							<div class="tc icon-img ">
								<img :src="item.image[0].thumbImg">
							</div>
							<div class="">
								<h3 class="text-left pro-txt">
									<span class="pre-sale-product" v-if="item.is_future&&item.is_future==1">预售</span>
									{{ item.name }}
								</h3>
								<div class="text-left width-100 product-price">
									<span class="fs-18 price-txt">
										<span class="fs-14">￥</span>
										{{ item.min_price }}
									</span>
									<span class="iconfont icon-31gouwuche"></span>
								</div>
							</div>
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<!-- 新品上市end -->
		<!-- 更多 -->
		<div class="bg-fff more-product is-more-pro">
			<el-row :gutter="10" class="container">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="more-product-title">
					<span class="iconfont icon-shuxian"></span>
					<h1>更多商品</h1>
					<span class="more-product-title-lable">More products</span>
				</el-col>
				<el-col :xs="12" :sm="12" :md="5" :lg="5" :xl="5" v-for="(item, index) in moreProduct" :key="index"
					class="product-item">
					<router-link :to="`/prodetail?id=${item.product_id}`" target="_blank">
						<div class="item-box ">
							<!-- <a href="product-detail.html"> -->
								<div class="tc icon-img ">
									<img :src="item.image[0].thumbImg">
								</div>
								<div class="">
									<h3 class="text-left pro-txt">
										<span class="pre-sale-product" v-if="item.is_future&&item.is_future==1">预售</span>
										{{ item.name }}
									</h3>
									<div class="text-left width-100 product-price">
										<span class="fs-18 price-txt">
											<span class="fs-14">￥</span>
											{{ item.min_price }}
										</span>
										<span class="iconfont icon-31gouwuche"></span>
									</div>
								</div>
							<!-- </a> -->
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<!-- 更多end -->
	</div>
</template>
<script>
	import 'bootstrap/dist/js/bootstrap.js'
	import 'bootstrap/dist/css/bootstrap.min.css'
	import 'bootstrap/dist/js/bootstrap.min'
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
	import {
		Public
	} from '@/api/public';
	var publicModel = new Public();
	export default {
		data() {
			return {
				loading: false,
				urls: [],
				newProduct: [],
				moreProduct: [],
				list: [],
				cateList: [],
				newProductBanner: [],
				bannerHeight: 500, //图片父容器的高度
				screenWidth: 0, //屏幕的宽度
				childBannerHeight: 340
			}
		},
		created() {
			this.loading = true;
			this.getCate();
			this.getList();
			this.getMoreList();
			this.getNewProduct();
			this.getBanner();
			this.getNewProductBanner();
		},
		mounted: function() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		},
		methods: {
			setSize: function() {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 500 / 1920 * this.screenWidth;
				this.childBannerHeight = 340 / 1920 * this.screenWidth;
			},
			//广告
			getBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'pc_banner'
				};
				adv.list(param).then(data => {
					that.urls = data.items;
					that.loading = false;
				}).catch(() => {
					that.loading = false;
				});
			},
			getNewProductBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'new_product_banner'
				};
				adv.list(param).then(data => {
					that.newProductBanner = data.items;
				}).catch(() => {

				});
			},
			//
			getCate() {
				var that = this;
				var param = {
					num: 100000,
					shop_id: 1
				};
				product.category(param).then(data => {
					that.cateList = data.items;
				}).catch(() => {

				});
			},
			//
			getList() {
				var param = {
					hot: 1,
					expand: 'sku',
					page: 1,
					'per-page': 6,
					num: 6
				};

				product.productList(param).then(res => {
					this.list = res.items;
				}).catch(() => {})
			},
			getNewProduct() {
				var param = {
					expand: 'sku',
					page: 1,
					'per-page': 15,
					num: 15
				};

				product.productList(param).then(res => {
					this.moreProduct = res.items;
				}).catch(() => {})
			},
			getMoreList() {
				var param = {
					expand: 'sku',
					page: 1,
					'per-page': 15,
					is_new: 1,
					num: 15
				};

				product.productList(param).then(res => {
					this.newProduct = res.items;
				}).catch(() => {})
			},
			selectNav(index) {
				this.activeIndex = index;
			},
			//跳转到详情页
			gotDetail(item, index) {
				this.$router.push({
					path: '/prodetail',
					query: {
						id: item.product_id,
						name: item.name,
						index: index
					}
				}).catch(() => {

				});
			},
			toCate(item, index) {
				this.$router.push({
					path: '/allproduct',
					query: {
						id: item.category_id,
						name: item.cat_name,
						index: index
					}
				}).catch(() => {

				});
			}
		}
	};
</script>
<style scoped>
	#app {
		font-size: 14px;
		background-color: #fff;
		color: #333;
	}
	.pre-sale-product{
		background-color: #ff0000;
		color: #fff;
		margin-right: 5px;
		font-size: 14px;
		padding: 1px 4px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.carousel-banner {
		height: 400px;
	}

	.carousel-banner el-carousel-item,
	.carousel-banner-item {
		height: 100%;
	}

	.carousel-banner .carousel-banner-item image {
		height: 100%;
	}

	a {
		color: #333;
	}

	.theme-color {
		color: #20b483;
	}

	.price-txt {
		color: #20b483;
	}

	.icon-gouwu {
		background-color: #20b483;
		color: #fff;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		color: #20b483 !important;
		font-weight: normal !important;
	}

	.icon-31gouwuche {
		background-color: unset !important;
		color: #20b483 !important;
		font-size: 20px;
		/* width: 30px;
		height: 30px;
		border-radius: 50%; */
	}

	.bg-fa {
		background-color: #fafafa;
	}

	.fs-14 {
		font-size: 14px;
	}

	.fs-16 {
		font-size: 16px;
	}

	.fs-18 {
		font-size: 18px;
	}

	.fs-32 {
		font-size: 32px;
	}

	h1 {
		font-size: 28px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	h2 {
		font-size: 24px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	h3 {
		font-size: 18px;
		text-align: center;
		width: 100%;
		padding: 20px 0;
	}

	.container,
	.container-fluid,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.el-header {
		background-color: #fff !important;
		color: #333;
		line-height: 60px;
		height: auto !important;
	}

	.top-login-box {
		height: auto !important;
		background-color: #f1f1f1 !important;
		padding: 0 10% !important;
		line-height: 40px !important;
		color: #666;
	}

	.website-log-box {
		padding: 20px 0 !important;

		&-item {
			display: flex;
			align-items: center;
		}
	}

	.website-log-box-right {
		display: flex;
		align-items: center;
	}

	.website-name {
		font-size: 36px;
		color: #20b483;
	}

	.search-input {
		border-radius: 0;
		height: 50px !important;
		line-height: 50px !important;
		padding: 0 5px;
		border: 1px solid #eee;
		width: 100%;
		border-radius: unset
	}

	el-input,
	input {
		padding-right: 30px;
		line-height: 50px !important;
		height: 50px !important;
	}

	.el-button--success {
		color: #FFF;
		background-color: #20b483;
		border-color: #20b483;
		height: 50px;
		border-radius: 0;
	}

	.nav-list .el-menu--horizontal>.el-menu-item {
		cursor: pointer;
		font-size: 16px;
	}

	.nav-list {
		padding: 20px 0 !important;
	}

	.el-menu.el-menu--horizontal {
		display: flex;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none !important;
		color: #20b483;
		padding: 0 40px;
		font-size: 16px;
	}

	.el-dropdown-link,
	.el-dropdown-link-item {
		font-size: 16px;
		cursor: pointer;
	}

	.el-dropdown-link-active {
		color: #20b483;
	}

	/* 轮播图 */
	.el-carousel__container {
		position: relative;
		height: 400px;
	}

	.el-carousel__item el-image {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 400px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.demo-image__lazy {
		height: 400px;
		overflow-y: auto;
	}

	.demo-image__lazy {
		height: 400px;
		overflow-y: auto;
	}

	.demo-image__lazy .el-image {
		display: block;
		min-height: 200px;
		margin-bottom: 10px;
		width: 100%;
	}

	.el-image {
		position: relative;
		display: inline-block;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	/* 轮播图end */
	/* 精选商品 */
	p {
		color: #666;
		text-align: center;
		font-size: 16px;
	}

	.jingxuan-box {
		padding: 30px 0;
	}

	.jingxuan {
		margin: 20px 0;
		border: 1px solid #eee;
		padding: 15px;
		border-radius: 6px;
	}

	.jingxuan .el-image {
		padding-right: 30px;
	}

	.jx-product-namem {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.pro-desc {
		color: #666;
	}

	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: inherit;
		font-weight: 500;
		line-height: 1.1;
		color: inherit;
	}

	.product-bottom-price-box {
		position: absolute;
		bottom: 0;
	}

	.product-price-box {
		margin-top: 20px;
		/* color: #20b483; */
		color: #ff0000;
		font-size: 22px;
	}

	.jingxuan .el-button--success {
		height: 40px;
		border-radius: 4px !important;
		margin: 20px 0;
	}

	/* end */
	/* 导航nav */
	.pc-nav-list {
		padding: 40px 0;
	}

	.nav-item {
		cursor: pointer;
		display: flex;
		align-items: center;
		background-color: #fff;
		margin-bottom: 20px;
		padding: 20px 10px;
		/* border-radius: 8px;
		box-shadow: 0px 2px 0px 0px #d3dcd8; */
		border: 1px solid #f8f9fa;
	}

	.nav-item img {
		width: 93px;
		height: 93px;
		margin-right: 4px;
	}

	.nav-item .iconfont {
		font-size: 68px;
		color: #20b483;
	}

	.nav-lable {
		font-weight: bold;
	}

	.nav-english {
		font-size: 12px;
		color: #999;
	}

	/* 导航nav end*/
	/* 更多商品 */
	.more-product {
		padding: 30px 0;
	}

	.more-product .product-item {
		margin: 10px 0.5%;
		padding: 5px !important;
	}

	.more-product-title {
		display: flex;
		align-items: center;
	}

	.more-product-title h1 {
		text-align: left;
		font-size: 24px;
		margin-top: 4px;
		margin-left: -6px;
		margin-right: 4px;
		width: auto !important;
	}

	.more-product-title .iconfont {
		font-size: 32px;
		color: #20b483;
	}

	.more-product-title-lable {
		color: #999;
	}

	.item-box {
		text-align: center;
		margin-bottom: 5rem;
		/* border: 1px solid #eee; */
		height: auto !important;
		background-color: #fff;
		/* margin: 10px 0;
		padding: 20px; */
	}

	.item-box .tc img {
		height: 100%;
		width: 100%;
	}

	.item-box h3 {
		font-size: 14px;
		font-weight: 500;
		color: #333333;
		margin: 10px 0 !important;
		padding: 0 10px;
		line-height: 20px;
		height: 40px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.item-box .width-100 {
		padding: 0 10px;
	}

	.pro-txt {
		text-align: left;
	}

	a {
		text-decoration: none !important;
	}

	.cart-img {
		width: 25px;
		height: 25px;
	}

	.text-left {
		display: flex;
		justify-content: space-between;
	}

	/* end */
	/* 底部 */
	.assure-box {
		display: flex;
		align-items: center;
		padding: 30px 0;
		color: #333;
	}

	.assure-item {
		display: flex;
		align-items: center;
	}

	.assure-box .assure-title {
		font-size: 18px;
	}

	.assure-box .assure-dec {
		color: #999;
	}

	.assure-box .assure-item img {
		width: 60px;
		height: 60px;
		margin-right: 10px;
	}

	.box {
		padding: 30px 0;
	}

	ol,
	ul {
		padding-left: 0;
	}

	li {
		list-style: none;
	}

	footer .tit {
		font-size: 18px;
		color: #666;
		line-height: 38px;
		margin-bottom: 20px;
	}

	footer a {
		font-size: 14px;
		line-height: 28px;
		color: #AAAAAA;
	}

	.saoma {
		font-size: 12px;
		margin-left: 5px;
	}

	/* end */
	.el-aside {
		color: #333;
	}

	/* 修改布局 */
	.hot-product .el-col-lg-5,
	.new-product .el-col-lg-5,
	.is-more-pro .el-col-lg-5 {
		width: 19%;
	}

	.new-product .product-item {
		background-color: #fff;
		border: none;
		padding: 0 !important;
		height: 340px !important;
	}

	.hot-product .product-item,
	.is-more-pro .product-item {
		margin: 10px 0.5%;
		padding: 0px !important;
		border: none;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
		border-radius: 2px;
	}

	.carousel-box {
		margin: 10px 0;
		width: 40%;
		height: 340px;
	}

	/* 响应式 */
	@media only screen and (min-width: 1600px) {}

	@media only screen and (max-width: 1600px) {}

	@media only screen and (max-width: 1200px) {
		.hot-product .el-col-lg-5 {
			width: 19%;
		}
	}

	@media only screen and (max-width: 992px) {
		.product-bottom-price-box {
			position: relative !important;
		}
	}

	@media only screen and (max-width: 767px) {
		.carousel-box {
			width: 100% !important;
			height: auto !important;
		}

		.hot-product-list,
		.product-item {
			width: 49% !important;
		}

		.nav-item img {
			width: 40px;
			height: 40px;
			margin-right: 2px;
		}

		.nav-item .iconfont {
			font-size: 24px;
		}

		.nav-lable {
			font-size: 12px;
		}

		.nav-english {
			display: none;
		}

		.product-bottom-price-box {
			position: relative;

		}

		.jingxuan {
			border: none;
			border-radius: 0;
			border-bottom: 1px solid #eee !important;
		}

		.jingxuan .el-image {
			padding-right: 0 !important;
		}

		.item-box {
			height: 280px;
		}

		.item-box h3 {
			height: 40px;
		}

		h1 {
			font-size: 20px;
		}

		.jx-product-namem {
			font-size: 16px;
		}

		.fs-18 {
			font-size: 16px;
		}

		.fs-32 {
			font-size: 28px;
		}

		.el-image__inner {
			border-radius: 4px;
		}

		.el-carousel,
		.el-carousel--horizontal,
		.el-carousel__container {
			height: 200px !important;
		}

		.el-carousel__container,
		.el-carousel__item,
		.el-carousel__mask {
			height: 200px !important;
		}

		footer .tit {
			text-align: center;
		}

		footer ul li {
			text-align: center;
		}


	}

	/* end */
</style>