import request from '@/utils/request'
class Adv {
    //广告
    list(params) {
        return request({
            url: '/carousel-item/list',
            method: 'post',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
    
};
export {
    Adv
};