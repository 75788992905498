<template>
	<div>

		<el-row class="all-category" v-loading="loading">
			<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
				<el-carousel :interval="50000" arrow="always" class="" :height=" bannerHeight + 'px' ">
					<el-carousel-item v-for="(item, index) in urls" :key="index" class="carousel-banner-item">
						<el-image :src="item.image"></el-image>
					</el-carousel-item>
				</el-carousel>
			</el-col>
			<!-- <el-container> -->
			<div class="container">
				<!-- 头部筛选 -->
				<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
					<div class="sort-box">
						<div class="sort-box-top">
							<input class=" input" type="text" placeholder="请输入商品名称搜索" v-model="inputVal" @keypress="search">
							<a href="#" @click="search">
								<el-button class="search-btn-box" type="success" icon="el-icon-search">搜索</el-button>
							</a>
						</div>
						<div class="case-category bf-f1f1f1">
							<ul id="myTab">
								<li class="first">已选择：</li>
								<li class="active" v-for="(item, index) in hasChooseList">
									<a href="#" data-toggle="tab">{{ item.cat_name }}</a>
								</li>
				
							</ul>
						</div>
						<div class="case-category">
							<ul id="myTab" class="category-list">
								<li class="first">类型：</li>
								<li :class="categoryDataIndex==index ? 'active' : ''" v-for="(item, index) in categoryData">
									<span @click.stop="chooseCate(item,index)">{{ item.cat_name }}</span>
								</li>
				
							</ul>
						</div>
					</div>
				</el-col>
				<!-- 头部筛选end -->
				<el-col :xs="12" :sm="12" :md='6' :lg="6" :xl="6" v-for="(item, index) in productData" :key="index">
					<!--更多产品 -->
					<div class="why-box">
						<router-link :to="`/prodetail?id=${item.product_id}`">
							<div class="tc icon-img ">
								<img :src="item.image[0].thumbImg" v-if="item.image && item.image[0].thumbImg" />
								<img src="../assets/no-pic.png" v-else />
								<div class="">
									<h3 class="text-left">
										<span class="pre-sale-product" v-if="item.is_future&&item.is_future==1">预售</span>
										{{ item.name }}
									</h3>
									<div class="text-left width-100 product-price">
										<span class="fs-18 price-txt">
											<span class="fs-14">￥</span>
											{{ item.min_price }}
										</span>
										<span class="iconfont icon-gouwu"></span>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md='24' :lg="24" :xl="24" v-if="productData.length==0">
					<el-empty description="暂无商品信息~"></el-empty>
				</el-col>
				<el-col class="pagination-list" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
					<el-pagination background layout="prev, pager, next" :total="(pageCount * 10)"
						v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
						:current-page="params.page">
					</el-pagination>
				</el-col>
			</div>
		</el-row>
		<!-- </el-container> -->
	</div>
</template>
<script>
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	import {
		Adv
	} from '@/api/adv';
	var adv = new Adv();
	export default {
		data() {
			return {
				productData: [], //商品列表
				show_search: 0, //是否展示搜索历史
				params: {
					page: 1,
					'per-page': 12,
					num:12
				},
				val: '',
				sort: '',
				moreSort: true, // 综合排序
				saleSort: false, //销量排序
				priceSort: false, //价格排序
				isUp: true, // 正序
				col: 2, //商品每行列数
				page: 1,
				searchHistory: [], //搜索记录
				inputShowed: false,
				inputVal: '', //搜索内容
				type_id: '',
				pageCount: 1, //总页数
				categoryData: [{
					cat_name: '全部',
					category_id: ''
				}],
				categoryDataIndex: 0,
				hasChooseList: [],
				loading: 'false',
				urls: [],
				bannerHeight: 500, //图片父容器的高度
				screenWidth: 0, //屏幕的宽度
			}
		},
		beforeCreate() {

		},
		created() {
			this.getCategory();
			this.getBanner();
			var obj = {
				category_id: '',
				cat_name: ''
			};
			obj.category_id = this.$route.query.id; //分类id
			obj.cat_name = this.$route.query.name; // 分类name
			this.inputVal = this.$route.query.input ? this.$route.query.input : this.inputVal;
			this.categoryDataIndex = this.$route.query.index === '' ? 0 : (this.$route.query.index + 1);
			this.hasChooseList.push(obj);
			//从首页搜索进来
			if (this.inputVal != '') {
				this.search();
				return false;
			}
			if (obj.category_id !== '') {
				var param = {
					cat_id: obj.category_id,
					page: 1,
					'per-page': 12,
					child: 1,
					num:12
				};
				this.loading = true;
				this._loadData(param);
			} else {
				this.loading = true;
				this._loadData();
			}
		},
		watch: {
			$route(to, from) {
				if (to.query.id != from.query.id) {
					var param = {
						cat_id: to.query.id,
						page: 1,
						'per-page': 12,
						child: 1,
						num:12
					};
					this.productData = [];
					this._loadData(param);
					this.categoryDataIndex = parseInt(to.query.index) + 1;
				}
			}
		},
		mounted: function() {
			this.screenWidth = window.innerWidth;
			this.setSize();
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
		},
		methods: {
			setSize: function() {
				// 通过屏幕宽度(图片宽度)计算高度
				this.bannerHeight = 500 / 1920 * this.screenWidth;
			},
			//广告
			getBanner() {
				var that = this;
				var param = {
					'num': 8,
					'key': 'pc_banner'
				};
				adv.list(param).then(data => {
					that.urls = data.items;
				}).catch(() => {

				});
			},
			/*加载商品数据*/
			_loadData(param = {}) {
				var that = this;
				var params = JSON.stringify(param) === '{}' ? this.params : param;
				product.productList(params).then(data => {
					that.loading = false;
					var products = data.items;
					that.productData = that.productData.concat(products);
					that.pageCount = data._meta.pageCount
				}).catch(() => {
					that.loading = false;
				})
			},
			//
			getCategory() {
				var that = this;
				var param = {
					num: 100000,
					shop_id: 1
				};
				product.category(param).then(data => {
					that.categoryData = that.categoryData.concat(data.items);
				}).catch(() => {

				});
			},
			//选择类型
			chooseCate(item, index) {
				this.categoryDataIndex = index;
				this.hasChooseList = [];
				this.hasChooseList.push(item);
				var param = {
					cat_id: item.category_id,
					page: 1,
					'per-page': 12,
					child: 1,
					num:12
				};
				this.loading = true;
				this.productData = [];
				this._loadData(param);
			},
			//currentPage 改变时会触发,改变页码
			currentChange(currentPage) {
				// 当前行变更时的处理逻辑
				this.params.page = currentPage;
				this.productData = []; //请求下一页前先情况上一页数据
				this._loadData();
			},
			//搜索
			search() {
				var param = {
					name: this.inputVal,
					...this.params
				};
				this.productData = [];
				this.loading = true;
				this._loadData(param);
			}
		}
	}
</script>
<style scoped>
	@import '../styles/allproduct.css';

	.carousel-banner {
		height: 400px;
	}

	.carousel-banner el-carousel-item,
	.carousel-banner-item {
		height: 100%;
	}

	.carousel-banner .carousel-banner-item image {
		height: 100%;
	}
	.pre-sale-product{
		background-color: #ff0000;
		color: #fff;
		margin-right: 5px;
		font-size: 14px;
		padding: 1px 4px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
</style>