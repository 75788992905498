import request from '@/utils/request'
class Login{
	//支付
	payment(param,callback){
		http.post('pay/code',param).then(data => {
			callback && callback(data);
		});
	}
	//短信登录
	smsLogin(param) {
    return request({
      url: '/auth/sms-login',
      method: 'post',
      data: param,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
	}
	//获取短信验证码
	sms(param, callback) {
		http.post('sms/sms', param).then(data => {
			callback && callback(data);
		});
	}
	//获取小程序用户信息
	xcxAuth(param,callback){
		http.post('third-party/wechat-mp',param).then(data => {
			callback && callback(data);
		});
	}
	//获取微信公众号用户信息
	wxAuth(param,callback){
		http.post('third-party/wechat',param).then(data => {
			callback && callback(data);
		});
	}
	//获取微信公众号用户信息
	wxBind(param,callback){
		http.post('member/auth/create',param).then(data => {
			callback && callback(data);
		});
	}
	//第三方信息授权绑定
	authBind(param,callback){
		http.post('auth/bind',param).then(data => {
			callback && callback(data);
		});
	}
	//注册
	register(param,callback) {
		http.post('site/register',param).then(data => {
			callback && callback(data);
		});
	}
	//系统配置
	config(param,callback) {
		http.post('config/info',param).then(data => {
			callback && callback(data);
		});
	}
	//首页导航
	getNav(param,callback) {
		http.post('nav/navitems',param).then(data => {
			callback && callback(data);
		});
	}
	//上传图片
	uploadImage(param,callback) {
		http.post('https://mall.bysdjy.com/api/upload/images-upload?fileparam=image&thumb=1&width=100&height=100',param).then(data => {
			callback && callback(data);
		});
	}
	//小程序二维码
	getQRCode(param,callback) {
		http.post('xcx-member/qrcode',param).then(data => {
			callback && callback(data);
		});
	}
};

export { Login };