<template>
	<el-container class="bg-fa">
		<el-row class="container cart-box">
			<el-col class="all-num-lable" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<span>全部商品（{{ $mStore.state.cartNum }}）</span>
			</el-col>
			<!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">&nbsp;</el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"></el-col>-->
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="cartData.length>0">
				<div class="table-responsive" v-for="(shopItem, shopIdx) in cartData" :key="shopIdx">
					<table class="table table-bordered">
						<thead>
							<tr class="">
								<th class="col-md-1">
									<div v-if="shopCount == cartData.length" @click="handleCheckAllChange()"
										class="iconfont icon-fuxuankuangxuanzhong align">
										<span style="font-size: 14px">全选</span>
									</div>
									<div v-else class="iconfont icon-checkbox align" @click="handleCheckAllChange()">
										<span style="font-size: 14px">全选</span>
									</div>
								</th>
								<th class="col-md-6" colspan="6">商品信息</th>
								<th class="col-md-1">价格</th>
								<th class="col-md-2">数量</th>
								<th class="col-md-1">小计</th>
								<th class="col-md-1">操作</th>
							</tr>
						</thead>
						<tbody>
							<!-- <tr style="border: none !important;">&nbsp;</tr> -->
							<tr>
								<td colspan="24"
									style="background-color: #fff !important;padding: 8px !important;text-align: left;">
									店铺：{{shopItem.shop.name}}
								</td>
							</tr>
							<tr v-for="(item, index) in shopItem.data" :key="index">
								<th scope="row" class="col-md-1">
									<div v-if="item.selected == 1 && item.skusStatus.status == 1"
										@click="selectItem(item.selected, item.id, item.skusStatus.status, index,item.skusStatus.msg)"
										class="iconfont icon-fuxuankuangxuanzhong"></div>
									<div v-else class="iconfont icon-checkbox"
										@click="selectItem(item.selected, item.id, item.skusStatus.status, index,item.skusStatus.msg)">
									</div>
								</th>
								<td class="col-md-6 product-name-box" @click.stop="goDetail(item)">
									<div class="product-img">
										<img :src="item.skus.image" v-if="item.skus && item.skus.image" />
										<img src="../assets/red.png" v-else />
									</div>
									<div class="product-name">
										<div class="">{{ item.product_name }}</div>
										<div class="cr-999 sku_values_lable">{{ item.sku_values || '' }}</div>
									</div>
								</td>
								<td class="col-md-1 product-price">
									<span class="fs-12">￥</span>
									{{ item.sale_price_real }}
									<span class="under-price"
										v-if="item.market_price&&item.market_price>0">￥{{item.market_price || ''}}</span>
								</td>
								<td class="col-md-2 product-num-box" v-if="item.skusStatus.status == 1">
									<ul>
										<li @click="changeNum(0, item.id, item.skus.sku_id)">-</li>
										<li class="product-num">
											<input v-model="item.num"
												@blur="inputNumber(item.id, item.skus.sku_id, item.num)" />
										</li>
										<li @click="changeNum(1, item.id, item.skus.sku_id)">+</li>
									</ul>
								</td>
								<td class="col-md-1 everyone-price cr-ff0000" v-if="item.skusStatus.status == 1">
									<span
										class="fs-14">￥</span>{{parseFloat(parseFloat(item.sale_price_real) * item.num).toFixed(2) }}
								</td>
								<td colspan="2" class="col-md-3 product-num-box" v-if="item.skusStatus.status!=1">
									<span class="fs12 cr-ff6600" v-if="item.skusStatus.status==0">此商品规格已失效</span>
									<span class="fs12 cr-ff6600"
										v-if="item.skusStatus.status==-3">抱歉，此商品在所选地址无货或不支持配送</span>
									<span v-if="item.skusStatus.status != -1" 
										style="padding: 2px 4px;border: 1px solid #ff0000;margin-left: 10px;
										color: #ff0000;border-radius: 4px;cursor: pointer;" 
										@click.stop="goDetail(item)">
										<span class="reset">重选</span>
									</span>
								</td>
								<td class="col-md-1">
									<el-button type="text" @click="deleteCart(item.id)">删除</el-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom-btn-box">
					<div scope="row" class="col-md-1 col-xs-6">
						<!-- <el-checkbox v-model="checkAll" @click="handleCheckAllChange()">全选</el-checkbox> -->
						<div v-if="shopCount == cartData.length&&cartData.length!=0" @click="handleCheckAllChange()"
							class="iconfont icon-fuxuankuangxuanzhong align">
							<span style="font-size: 14px">全选</span>
						</div>
						<div v-else class="iconfont icon-checkbox align" @click="handleCheckAllChange()">
							<span style="font-size: 14px">全选</span>
						</div>
					</div>
					<div class="col-md-1 col-xs-6">
						<el-button class="del-btn" type="text" @click="del('', 'all')">删除</el-button>
					</div>
					<div class="col-md-10 col-xs-12 bottom-btn-right">
						<div class=" col-xs-12">已选商品：<span class="cr-ff0000 font-weight fs-18">{{ totalNum }}
							</span>件
						</div>
						<div class="total-price col-xs-12">合计（不含运费）：<span class="cr-ff0000 font-weight fs-18">
								{{ totalPrice }}
							</span>元</div>
						<div class=" col-xs-12" @click="toPay"><button type="button"
								class="btn btn-success jiesua-btn">结算</button>
						</div>
					</div>
				</div>
			</el-col>
			<el-col class="empty-cart" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-else>
				<el-empty description="购物车还没有数据,快去购物吧~"></el-empty>
				<router-link to="/allproduct">
					<div class="to-shopping">去逛逛</div>
				</router-link>
			</el-col>

		</el-row>
	</el-container>
</template>
<script>
	import {
		InputNumber
	} from 'element-ui';
	import {
		Cart
	} from '@/api/cart';
	var cart = new Cart();
	import {
		Product
	} from '@/api/product';
	var product = new Product();
	export default {
		data() {
			return {
				checkAll: false,
				isIndeterminate: true,
				list: [],
				cartData: [],
				totalPrice: 0, //合计
				totalNum: 0, //总数
				dialogVisible: false,
				multipleSelection: [],
				ids: [], //已选中的商品id
				hasGoodsSelected: false,
				checked: true, //
				checkedFalse: false,
				shopCount: 0
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			//获取购物车数据
			loadData() {
				var that = this;
				var param = {}
				cart.list(param).then(data => {
					var itemList = data.items;
					//声明一个空的数组
					var items = [];
					var able = [];
					var totalNum = 0;
					//遍历数据
					for (let i in itemList) {
						//检查购物车有下架并且已选择的商品，然后重新选择
						for (let j in itemList[i].data) {
							if (itemList[i].data[j].selected == 1) {
								totalNum++;
							} else {

							}
							if (itemList[i].data[j].skusStatus.status != -1 && itemList[i].data[j].selected == 1) {
								able.push(parseInt(itemList[i].data[j].id))
							}
							if (itemList[i].data[j].skusStatus.status == -1) {
								itemList[i].data[j].selected == 0
							}
						}
						//push到空数组里
						items.push(itemList[i]);
					};
					//已选商品数量
					this.totalNum = totalNum;
					if (able) {
						var param = {
							cart_ids: able
						}
						cart.getcalculatorTototal(param).then(data => {

						});
					} else {

					};
					var cartData = data.items = items;
					if (cartData) {
						cartData = that._isShopSelected(cartData);
						that.cartData = cartData;
						that.totalPrice = data.sale_real_total;
						if (data.items && data.items.length > 0) {
							that.$mStore.commit('setCart', data.items[0].data.length);
						} else {
							that.$mStore.commit('setCart', 0);
						}
					}

				}).catch(() => {

				});
			},
			_isShopSelected(cartData) {
				var len = cartData.length,
					shopCount = 0;
				this.hasGoodsSelected = false;
				for (let i = 0; i < len; i++) {
					var childLen = cartData[i].data.length;
					var flag = 1;
					for (let j = 0; j < childLen; j++) {
						if (cartData[i].data[j].selected == 0) {
							flag = 0;
						} else if (cartData[i].data[j].selected == 1) {
							this.hasGoodsSelected = true;
						}
					}
					cartData[i].shop.isShopSelectedAll = flag;
					if (cartData[i].shop.isShopSelectedAll == 1) {
						shopCount++
					}
				}
				this.shopCount = shopCount;
				return cartData;
			},
			//全选购物车true/false
			handleCheckAllChange(status = '') {
				var status = this.shopCount == this.cartData.length ? 'true' : 'false',
					cartData = this.cartData,
					len = cartData.length;
				if (len == 0) {
					this.$message.error('购物车没有数据，请先添加商品到购物车中~');
					return;
				}
				if (status == 'true') {
					for (let i = 0; i < len; i++) {
						var childLen = cartData[i].data.length;
						cartData[i].shop.isShopSelectedAll = 0;
						for (let j = 0; j < childLen; j++) {
							cartData[i].data[j].selected = 0;
						}
					}
				} else if (status == 'false') {
					for (var i = 0; i < len; i++) {
						var childLen = cartData[i].data.length;
						cartData[i].shop.isShopSelectedAll = 1;
						console.log(cartData[i].shop.isShopSelectedAll);
						for (var j = 0; j < childLen; j++) {
							cartData[i].data[j].selected = 1;

						}
					}
				}
				this.cartData = cartData;
				this._resetCalculatorTotoltal();
			},
			//单选某一项,购物车选项
			selectItem(status, id, sku_status, index,msg='') {
				var id = id,
					status = status,
					sku_status = sku_status;
				if (sku_status != 1) {
					this.$message.error(msg);
					return false;
				}
				if (this.cartData[0].data[index].selected == 1) {
					this.cartData[0].data[index].selected = 0;
				} else {
					this.cartData[0].data[index].selected = 1
				}
				this._resetCalculatorTotoltal();
			},
			/*根据商品id得到 商品所在下标*/
			_getProductIndexById: function(id) {
				var cartData = this.$data.cartData,
					len = cartData.length;
				for (let i = 0; i < len; i++) {
					var childlen = cartData[i].data.length;
					for (let j = 0; j < childlen; j++) {
						if (cartData[i].data[j].id == id) {
							return {
								i,
								j
							};
						}
					}
				}
			},
			/*勾选更新总价*/
			_resetCalculatorTotoltal(ids = Array()) {
				var cartData = this._isShopSelected(this.cartData);
				var selectedIds = this._getSelectedGoods();
				var param = {
					cart_ids: selectedIds
				};
				//更新已选商品数量
				this.totalNum = selectedIds.length;
				cart.getcalculatorTototal(param).then(data => {
					if (data.status == 1) {
						this.cartData = cartData;
						this.totalPrice = data.total;
					}
				}).catch((res) => {

				});
			},
			// 获取所有已经勾选的id
			_getSelectedGoods() {
				var cartData = this.cartData,
					len = cartData.length,
					selectedIds = [];
				for (let i = 0; i < len; i++) {
					var childlen = cartData[i].data.length;
					for (let j = 0; j < childlen; j++) {
						if (cartData[i].data[j].selected == 1 && cartData[i].data[j].skusStatus.status != -1) {
							selectedIds.push(parseInt(cartData[i].data[j].id));
						}
					}
				}
				return selectedIds;
			},
			//增加/减少数量
			changeNum(type, id, sku_id) {
				var that = this;
				var id = id,
					sku_id = sku_id,
					type = type,
					shopIdx = this._getProductIndexById(id).i,
					index = this._getProductIndexById(id).j;
				var num = product.changeCounts(type, this.cartData[shopIdx].data[index].num);
				if (type == 1) {
					//数量加
					var param = {
						num: num
					}
				} else if (type == 0) {
					//数量减
					var param = {
						num: num,
						retry: 1
					}
				}
				param.sku_id = sku_id;
				param.is_self = 1;
				cart.add(param).then(data => {
					if (data.status == 1) {
						that.cartData[shopIdx].data[index].num = data.num;
						that.cartData = that.cartData
						that._resetCalculatorTotoltal();
					} else {
						that.$message.error(data.msg);
					}
				});
			},
			//手动输入商品数目
			inputNumber(id, sku_id, num) {
				var currentNum = num;
				var maxCount, selected_skus_id;
				var id = id,
					sku_id = sku_id,
					shopIdx = this._getProductIndexById(id).i,
					index = this._getProductIndexById(id).j;
				maxCount = this.cartData[shopIdx].data[index].skus.stock;
				if (currentNum && currentNum < 1) {
					currentNum = 1;
					this.$message.error('数量必须是正整数!');
					return;
				}
				if (currentNum > maxCount) {
					currentNum = maxCount;
					if (maxCount == 0) {
						currentNum = 1;
						this.$message.error('暂无库存~');
						return;
					} else {
						var msg = '库存仅剩' + maxCount + '件了~';
						this.$message.error(msg);
						return;
					}
				}
				var param = {
					sku_id: sku_id,
					num: currentNum,
					retry: 1,
					is_self: 1
				}
				cart.add(param).then(data => {
					if (data.status == 1) {
						this.cartData[shopIdx].data[index].num = data.num;
						this.cartData = this.cartData;
						this._resetCalculatorTotoltal();
						this.$message.success('数量修改成功~');
					} else {
						var msg = data.msg ? data.msg : '操作失败';
						this.$message.error(msg);
					}
				}).catch(() => {

				});
			},
			/*删除购物车指定商品*/
			deleteCart(id) {
				var id = id,
					index = this._getProductIndexById(id),
					that = this;
				var param = {
					id: id
				};
				this.$confirm('确定要删除该商品吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					cart.delete(param).then(data => {
						if (data.status == 1) {
							that.loadData();
						}
					}).catch(() => {

					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			//删除
			del(index, type) {
				var that = this;
				if (this.hasGoodsSelected) {
					this.$confirm('此操作将删除所选商品, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.confirmDel();
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				} else {
					this.$message.error('请选择要删除的商品~');
				}

			},
			//确定删除
			confirmDel() {
				var that = this
				var ids = [];
				var sku_id = [];
				var items = this.cartData;
				items.forEach((shop, shop_index) => {
					shop.data.forEach((item, index) => {
						if (item.selected == 1) {
							ids.push(item.id);
						}
					})
				})
				var param = {
					id: ids
				}
				cart.delete(param).then(data => {
					if (data.status == 1) {
						that.$message.success("删除成功！");
						that.loadData();
					} else {
						var msg = data.msg ? data.msg : '删除失败！';
						that.$message.error(msg);
					}
				}).catch(() => {

				});
			},
			//跳转到详情页
			goDetail(item) {
				this.$router.push({
					path: '/prodetail',
					query: {
						id: item.product_id,
						name: item.name
					}
				});
			},

			//去结算
			toPay() {
				this.$router.push({
					path: '/confirm',
					query: {
						from: 'cart'
					}
				}).catch(() => {

				});
			}
		}
	}
</script>
<style scoped>
	@import '../styles/cart.css';
</style>