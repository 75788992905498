const getLocationOrigin = () => {
    //return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    return 'https://mini.binon.com.cn/api/v1'
  }
  const url='https://mini.binon.com.cn/api/v1'
  const urlImg='https://mini.binon.com.cn'
  const companyName = '比农出品'
  const version = 'V1.0.0'
  const baiduKey = '百度key'
  const cacheFix='binon_'
  
  export default {
    url,
	urlImg,
    version,
    companyName,
    getLocationOrigin,
    baiduKey,
    cacheFix
  }
  