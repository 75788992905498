<template>
    <el-container>
        <el-row guter="8" class="container">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <form class="form-horizontal" role="form">
                    <div class="form-group">
                        <label for="id" class="col-sm-2 control-label">账号</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="id" v-model="param.mobile" placeholder="请输入手机号">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" class="col-sm-2 control-label">密码</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="param.code" id="password"
                                placeholder="请输入密码">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 btn-box">
                            <button type="button" class="btn btn-success" @click="toLogin">登录</button>
                        </div>
                    </div>
                </form>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import Lockr from 'lockr'
import { Login } from '@/api/login'
var login = new Login();
export default {
    data() {
        return {
            param: {
                code: '',
                group: 'weixin',
                mobile: ''
            },
            currentPage: ''
        }
    },
    created() {
        var currentPage = localStorage.getItem('currentPage');
        this.currentPage = JSON.parse(currentPage);
    },
    methods: {
        toLogin() {
            var param = this.param;
            var that = this;
            login.smsLogin(param).then(res => {
                var access_token = res.access_token;
                // 存储数据
                localStorage.setItem('access_token', access_token);
                var url = this.currentPage&&this.currentPage.path ? this.currentPage.path : '/indexMain';
				var query=this.currentPage&&this.currentPage.query ? this.currentPage.query : {};
                setTimeout(function () {
                    that.$router.push({
                        path: url,
                        query: query
                    }).catch(() => {

                    });
                }, 2000);
                this.$message.success('登录成功！');
            }).catch(() => {

            });
        }
    }
}
</script>
<style scoped>
.form-horizontal {
    width: 60%;
    margin: auto;
    margin-top: 40px;
    padding: 40px 20px;
    background-color: #f9f9f9;
    margin-bottom: 40px;
}

.form-horizontal input {
    width: 100%;
    padding: 10px;
}

.login-box {
    margin: 40px auto;
    width: 70%;
}

.login-box .form-control {
    height: 40px;
}

.login-box .btn-success {
    margin: 0 100px;
    width: 100px;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.form-group label {
    width: 20%;
    text-align: center;
}

.form-group div {
    width: 80%;
}

.btn-success {
    width: 120px;
    margin: auto;
    padding: 10px 0;
}

.btn-box {
    margin-left: calc(50% - 60px);
}
</style>