<template>
    <div>
        <el-row class="container">
			<el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
			    <div class="new-title" >{{title || ''}}</div>
			</el-col>
            <el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
                <div class="news-detail" v-html="detail.content"></div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { News} from '@/api/news';
var news = new News(); 
export default{
    data(){
        return{
            detail:{},
            id:'',
			title:''
        }
    },
    created(){
        this.id = this.$route.query.id;
        this.getDetail();
    },
    methods:{
        getDetail(){
            var param={
                id:this.id
            };
            news.detail(param).then(res => {
                this.detail = res.data;
				this.title=res.title;
                //只有一个规格值时，默认选中
                
            }).catch(() => {
                
            })
        }
    }
}
</script>
<style scoped>
.news-detail{
    /* border-top: 1px solid #eee; */
    padding: 20px 0;
}
/* .news-detail>>>img{
    margin: 0 0 !important;
    max-width: 100% !important;
} */
.new-title{
	    font-size: 20px;
	    padding: 40px 0 20px;
	    text-align: center;
	    font-weight: bold;
}
</style>