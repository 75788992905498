/** 首页路由 */
import Layout from '@/views/index'

const indexRouter = {
  path: '/index',
  component: Layout,
  redirect: '/index',
  name: 'index',
  hidden: true,
  meta: {
    title: '首页'
  },
  children: [{
    path: 'index',
    component: () => import('@/views/index')
  }]
}

export default indexRouter
