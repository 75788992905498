<template>
	<el-container class="my-order">
		<el-aside width="200px" style="margin-bottom:20px;">
			<el-menu :default-active="left_menu_index" class="el-menu-vertical-demo" :collapse-transition="true"
				:unique-opened='false' @open="handleOpen" @close="handleClose" :collapse="isCollapse">
				<el-submenu v-for="(item, index) in leftMenu" :key="item.id" :index="(index + 1) + ''">
					<template slot="title">
						<i :class="item.icon"></i>
						<span>{{ item.name }}</span>
					</template>
					<el-menu-item v-for="(citem, cindex) in item.child" :key="citem.id"
						:index="(index + 1) + '-' + (cindex + 1) + ''" @click="openChild(citem.type)">
						<i :class="citem.icon"></i>
						<span slot="title">{{ citem.name }}</span>
					</el-menu-item>
				</el-submenu>
			</el-menu>

		</el-aside>
		<el-main v-loading="loading">
			<!-- 我的订单 -->
			<el-tabs v-if="type == 'order'" v-model="activeName" @tab-click="changeOrderItem">
				<el-tab-pane v-for="(item, tab_index) in orderNav" :key="tab_index" :index="tab_index + ''" :label="item.name"
					:name="item.value">
					<div class="table-responsive order-list">
						<!-- <div class="order-search-box">
							<input type="text" placeholder="请输入商品名称或订单号搜索" />
							<el-button type="primary" plain icon="el-icon-search">搜索</el-button>
						</div> -->
						<table class="table table-bordered my-order-thead">
							<thead>
								<tr class="order-product-top">
									<th class="col-md-1" style="display: none;">
										<!-- <input type="checkbox" aria-label=""> -->
									</th>
									<th class="col-md-5" style='text-align:left'>商品信息</th>
									<th class="col-md-1">单价</th>
									<th class="col-md-1">数量</th>
									<th class="col-md-1">小计</th>
									<th class="col-md-2">订单状态</th>
									<th class="col-md-1">操作</th>
								</tr>
							</thead>

						</table>
						<div class="pro-item">
							<table class="table" v-for="(order, p_index) in orderList" v-if="orderList.length>0">
								<thead>
									<tr>
										<th class="col-12 border-top-solid" style='text-align:left' colspan="12">
											<span class="pro-order-no">订单号：{{ order.order_no }}</span>
											<span class="pro-list-order-date">时间：{{ order.create_time | time }}</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in order.orderSku">
										<th scope="row" class="col-md-1" style="display: none;">
											<!-- <input type="checkbox" aria-label=""> -->
										</th>
										<td class="col-md-5">
											<div class="display">
												<div class="product-img">
													<img :src="item.sku_thumbImg" v-if='item.sku_thumbImg' />
												</div>
												<div class="product-name-sku-box">
													<div class="product-name">
														<span v-if="item.is_future&&item.is_future==1" style="color: #ff0000;">【预售】</span>
														{{ item.goods_name }} 
													</div>
													<div class="product-sku">{{ item.sku_value }}</div>
												</div>
											</div>
										</td>
										<td class="col-md-1 cr999">
											<span style="font-size: 12px;">￥</span>
											<span>{{ item.sku_sell_price_real }}</span>
										</td>
										<td class="col-md-1 cr999">
											<span style="font-size: 12px;">x</span>
											<span>{{ item.num }}</span>
										</td>
										<!-- 订单只有一个商品时 -->
										<!-- <td class="col-md-1" v-if="order.orderSku.length == 1">
											￥{{ item.sku_sell_price_real }}
										</td>
										<td class="col-md-1" v-if="order.orderSku.length == 1">
											{{ item.num }}
										</td> -->
										<td class="col-md-1 cr999" v-if="order.orderSku.length == 1">
											<span style="font-size: 12px;">￥</span>
											<span>{{(parseFloat(item.sku_sell_price_real) * item.num.toFixed(2))}}</span>
										</td>
										<!-- 订单只有一个商品时end -->
										<!-- 订单多个商品时 -->
										<!-- <td :rowspan="order.orderSku.length" class="col-md-1 border-left"
											v-if="order.orderSku.length > 1 && index == 0">
											￥{{ order.pay_amount }}
										</td>
										<td :rowspan="order.orderSku.length" class="col-md-1"
											v-if="order.orderSku.length > 1 && index == 0">
											{{ order.orderSku.length }}
										</td> -->
										<td :rowspan="order.orderSku.length" class="col-md-1 border-left cr999"
											v-if="order.orderSku.length > 1 && index == 0">
											<span style="font-size: 12px;">￥</span>
											<span>{{parseFloat(order.pay_amount)}}</span>
										</td>
										<!-- 订单多个商品时end -->
										<td class="col-md-2 border-l-r" v-if="index == 0"
											:rowspan="order.orderSku.length">
											<div v-if="order.status == 1" class='cr-ff0000'>待支付</div>
											<div v-if="order.status == 2 && order.group_status == 1" class='cr-ff0000'>
												待成团
											</div>
											<div v-else-if="order.status == 2">等待卖家发货</div>
											<div
												v-if="order.status == 3 && (order.delivery_id == 1 || order.delivery_id == 2)">
												待核销</div>
											<div v-if="order.status == 3 && order.delivery_id == 0">待收货</div>
											<div v-if="order.status == 5" class="cr-999">已评价</div>
											<div v-if="order.status == 6" class="cr-999">已退款</div>
											<div v-if="order.status == 10" class='cr-ff6600'>退款中</div>
											<div v-if="order.status == 11">商家拒绝退款</div>
											<div v-if="order.status == 8" class="cr-999">已取消</div>
											<div v-if="order.status == 9" class="cr-999">交易已关闭</div>
											<div class="look" @click.stop="toLogic(order.id)" v-if="order.status >2 && order.status<8">
												查看物流
											</div>
											<div class="look" @click.stop="toOrderDetail(order.id)">订单详情</div>
										</td>
										<td class="col-md-1 operation-box" :rowspan="order.orderSku.length"
											v-if="index == 0">
											<div v-if="order.status == 1" class="cr-666"
												@click.stop="_cancelOrder(order.id, index)">取消订单<br /></div>
											<div v-if="order.status == 1" class="cr-sucess" @click="toPay(order.id)">
												去支付<br /></div>
											<div @click="toRefundOrder(order.id)"
												v-if="order.type != 1 && order.payment_status == 1 && (order.status == 2)">
												申请退款<br /></div>
											<div @click="_confirmOrder(order.id)"
												v-if="(order.status == 3 && order.delivery_id == 0) || (order.status == 11 && order.delivery_status == 1 && order.delivery_id == 0)">
												确认收货<br /></div>
											<div class="evaluate-box" v-if="order.status == 4">
												<el-popover placement="right" width="400" trigger="click">
													<el-form label-width="80px">
														<div class="add-address-title">订单商品列表</div>
														<div v-for="(evaluate,index) in orderProduct">
															<div class="evaluate-item">
																<div class="evaluate-goods">
																	<img :src='evaluate.sku_thumbImg' />
																	<div class="good-name">{{evaluate.goods_name}}</div>
																</div>
																<div class="evaluate-btn" v-if="evaluate.is_comment==0"
																	@click="confirmEvaluate(evaluate.id,evaluate.shop_id)">
																	评价该商品</div>
																<div v-if="evaluate.is_comment==1" class="had-eval">已评价
																</div>
															</div>
														</div>
													</el-form>
													<el-button slot="reference"
														@click="toEvaluate(order.id,order.shop_id)">评价</el-button>
												</el-popover>
											</div>

										</td>
									</tr>
								</tbody>
							</table>
							<el-empty style="width: 100%;" class="align" description="暂无订单信息~"
								v-if="orderList.length==0"></el-empty>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
			<el-pagination v-if="type == 'order'" background layout="prev, pager, next" :total="(pageCount * 10)"
				v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
				:current-page="params.page">
			</el-pagination>
			<!-- 我的订单end -->
			<!-- 个人信息 -->
			<div class="my-box" v-if="type == 'my'">
				<div class="placehoder-title">个人信息</div>
				<el-form label-width="80px">
					<el-form-item label="头像">
						<!-- <el-image class="my-avter" v-if="info.avatarUrl || info.auth"
                            :src="info.auth ? info.auth.avatarUrl : info.avatar"></el-image>
                        <el-image class="my-avter" src="/src/assets/no-avter.png"></el-image> -->
						<el-form-item label="上传图片" required>
							<el-upload class="avatar-uploader" :show-file-list="false" :action="requestUrl"
								:data="fileData" :headers="headers" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<img v-if="info.avatarUrl || info.auth"
									:src="info.auth ? info.auth.avatarUrl : info.avatar" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								<div slot="tip" class="el-upload__tip">建议尺寸：200*100px</div>
							</el-upload>
						</el-form-item>

					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="info.mobile"></el-input>
					</el-form-item>
					<el-form-item label="昵称">
						<el-input v-model="info.username"></el-input>
					</el-form-item>
					<div class="my-btn" @click="save">
						<el-button type="primary">保存</el-button>
					</div>
				</el-form>
			</div>
			<!-- 个人信息end -->
			<!-- 收货地址 -->
			<div class="my-address" v-if="type == 'address'">
				<el-tabs v-model="activeAddress" type="card" @tab-click="handleAddress">
					<el-tab-pane label="我的收货地址" name="address_list">
						<el-table v-if="addressList.length>0" :data="addressList" style="width: 100%">
							<el-table-column fixed="right" label="标识" width="80">
								<template slot-scope="scope">
									<div v-if='scope.row.is_default == 1' class="default">默认地址</div>
								</template>
							</el-table-column>
							<el-table-column prop="userName" label="收货人" width="100">
							</el-table-column>
							<el-table-column prop="provinceName" label="省" width="150">
							</el-table-column>
							<el-table-column prop="cityName" label="市" width="80">
							</el-table-column>
							<el-table-column prop="countyName" label="区" width="80">
							</el-table-column>
							<el-table-column prop="detailInfo" label="详细地址" width="200">
							</el-table-column>
							<el-table-column prop="telNumber" label="电话" width="120">
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="200">
								<template slot-scope="scope">
									<el-button @click="editAddress(scope.row)" type="text" size="small">编辑</el-button>
									<el-button type="text" size="small"
										@click="setDefault(scope.row)">设为默认地址</el-button>
									<el-button @click="delAddress(scope.row,scope)" type="text"
										size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-empty v-else description="暂无地址信息~"></el-empty>
						<el-pagination background layout="prev, pager, next" :total="(pageCount * 10)"
							v-model:currentPage="params.page" highlight-current-row @current-change="currentChange"
							:current-page="params.page">
						</el-pagination>
					</el-tab-pane>
					<el-tab-pane class="demo-form" label="新增收货地址" name="add_address">
						<el-form label-width="80px" class="add-address">
							<el-form-item label="姓名:">
								<el-input v-model="userName"></el-input>
							</el-form-item>
							<el-form-item label="手机">
								<el-input v-model="telNumber"></el-input>
							</el-form-item>
							<el-form-item label="省市区:">
								<el-select v-model="provinceName" placeholder="请选择省" @change="changeProvince">
									<el-option v-for="(item, index) in province" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
								<el-select v-model="cityName" placeholder="请选择市" @change="changeCity">
									<el-option v-for="(item, index) in city" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
								<el-select v-model="countyName" placeholder="请选择区" @change="changeArea">
									<el-option v-for="(item, index) in area" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item class="address-detail" label="详细地址">
								<el-input v-model="detailInfo"></el-input>
							</el-form-item>
							<el-form-item class="" label="是否默认">
								<el-radio v-model="is_default" :label="1" @input="changeDefaule">是</el-radio>
								<el-radio v-model="is_default" :label="0" @input="changeDefaule">否</el-radio>
							</el-form-item>
							<button type="button" class="btn btn-success" @click="createAddress('add')">保存</button>
						</el-form>
					</el-tab-pane>
					<el-tab-pane class="demo-form" label="编辑地址" name="edit_address" v-if='edit_address'>
						<el-form label-width="80px" class="add-address">
							<el-form-item label="姓名:">
								<el-input v-model="addressDetail.userName"></el-input>
							</el-form-item>
							<el-form-item label="手机">
								<el-input v-model="addressDetail.telNumber"></el-input>
							</el-form-item>
							<el-form-item label="省市区:">
								<el-select v-model="provinceName" :placeholder="addressDetail.provinceName"
									@change="changeProvince">
									<el-option v-for="(item, index) in province" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
								<el-select v-model="cityName" :placeholder="addressDetail.cityName"
									@change="changeCity">
									<el-option v-for="(item, index) in city" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
								<el-select v-model="countyName" :placeholder="addressDetail.countyName"
									@change="changeArea">
									<el-option v-for="(item, index) in area" :key="item.id" :label="item.name"
										:value="index">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item class="address-detail" label="详细地址">
								<el-input v-model="addressDetail.detailInfo"></el-input>
							</el-form-item>
							<el-form-item class="" label="是否默认">
								<el-radio v-model="addressDetail.is_default" :label="1"
									@input="changeDefaule">是</el-radio>
								<el-radio v-model="addressDetail.is_default" :label="0"
									@input="changeDefaule">否</el-radio>
							</el-form-item>
							<button type="button" class="btn btn-success" @click="createAddress('edit')">提交编辑信息</button>
						</el-form>
					</el-tab-pane>
				</el-tabs>

			</div>
			<!-- 收货地址end -->
			<!-- 我的优惠券 -->
			<div class="my-coupon" v-if="type == 'coupon'">
				<el-tabs v-model="activeCoupon" type="card" @tab-click="selectCoupon">
					<el-tab-pane :label="item.name" :name="item.name" class="my-coupon-list"
						v-for='(item, index) in couponMenu'>
						<div class="coupon_new couponItem" v-if="couponList.length>0"
							v-for="(item, index) in couponList">
							<div class="coupon_content_new">
								<div class="couponLeftInfo">
									<div class="couponTopInfo" v-if="item.coupon.use_type == 1">
										<div class="priceContainer"><span class="priceSign">￥</span>
											<span class="savePrice">
												{{ item.coupon.use_money | toInt }}</span>
										</div>
										<div class="useCondition">满{{ item.coupon.money_condition | toInt }}可用</div>
									</div>
									<div class="couponTopInfo" v-if="item.coupon.use_type == 2">
										<div class="priceContainer">
											<span class="savePrice">{{ item.coupon.use_money | toInt }}</span>
											<span class="priceSign">折</span>
										</div>
										<div class="useCondition">满{{ item.coupon.money_condition | toInt }}可用</div>
									</div>
									<div class="validTime">
										{{ item.coupon.use_start | time }}至
										{{ item.coupon.use_end | time }}
									</div>
									<div class="couponBottomInfo">
										<div class="couponName">
											<span v-if="item.coupon.product_limiter == 0">全场可用</span>
											<span v-if="item.coupon.product_limiter == 1">指定商品可用</span>
											<span v-if="item.coupon.product_limiter == 2">指定类目可用</span>
										</div>
									</div>
								</div>
								<div class="couponRightInfo" v-if='couponIndex == 0'>
									<div class="couponReceive" @click='toUse(item.coupon)'>立即使用</div>
								</div>
							</div>
						</div>
						<el-empty style="width: 100%;" class="align" description="暂无优惠券信息~"
							v-if="couponList.length==0"></el-empty>
					</el-tab-pane>
				</el-tabs>
			</div>
			<!-- 我的优惠券end -->
		</el-main>
	</el-container>
</template>
<script>
	import {
		regionData,
		CodeToText,
		TextToCode,
		codeToText,
	} from "element-china-area-data"; //导入省市区
	import {
		User
	} from '@/api/user';
	var user = new User();
	import {
		Address
	} from '@/api/address';
	var evaluate = new Evaluate();
	import {
		Evaluate
	} from '@/api/evaluate.js';
	var address = new Address();
	import moment from '@/utils/moment';
	import helper from '@/utils/helper';
	import {
		Message,
		MessageBox
	} from 'element-ui';
	export default {
		data() {
			return {
				loading: false,
				isCollapse: false,
				activeName: 'all',
				type: 'my', //order订单列表 my个人信息 address收货地址 coupon优惠券 refund售后
				info: {},
				orderNav: [{
						name: '全部订单',
						value: 'all',
						status: ''
					},
					{
						name: '待付款',
						value: 'untpay',
						status: 1
					},
					{
						name: '待发货',
						value: 'undelivery',
						status: 2
					},
					{
						name: '待收货',
						value: 'untreceived',
						status: 3
					},
					{
						name: '待评价',
						value: 'unevaluate',
						status: 4
					}
				],
				currentOrderIndex: 0,
				orderList: [],
				leftMenu: [{
						name: '会员中心',
						id: 1,
						icon: 'el-icon-s-custom',
						child: [{
								name: '个人信息',
								id: 1_1,
								type: 'my',
								icon: 'el-icon-user-solid'
							},
							{
								name: '收货地址',
								id: 1_2,
								type: 'address',
								icon: 'el-icon-location'
							},
							{
								name: '我的优惠券',
								id: 1_3,
								type: 'coupon',
								icon: 'el-icon-s-ticket'
							},
							{
								name: '退出登录',
								id: 1_4,
								type: 'out-login',
								icon: 'el-icon-switch-button'
							}
						]
					},
					{
						name: '订单列表',
						id: 2,
						icon: 'el-icon-menu',
						child: [{
								name: '我的订单',
								id: 2_1,
								type: 'order',
								icon: 'el-icon-s-order'
							}
							/* {
								name: '退款/售后',
								id: 2_2,
								type: 'refund',
								icon: 'el-icon-s-release'
							} */
						]
					}
				],
				addressList: [],
				activeAddress: 'address_list',
				radio: '1',
				left_menu_index: '1-1',
				couponMenu: [{
						name: '未使用',
						status: 0
					},
					{
						name: '已使用',
						status: 1
					},
					{
						name: '已过期',
						status: 2
					}
				],
				couponIndex: 0,
				activeCoupon: '未使用',
				couponList: [],
				addressParam: {
					address_name: "",
					city_id: 2298,
					detailInfo: "明秀路12",
					lat: "",
					lng: "",
					province_id: 2297,
					region_id: 2299,
					telNumber: "18777749011",
					type: "",
					userName: "李四"
				},
				fileData: { // 接口需要的额外参数
					category: 12
				},
				requestUrl: this.BNConfig.urlImg +
					'/api/upload/images-upload?fileparam=image&thumb=1&width=200&height=200',
				headers: { // 请求头部参数
					accessToken: localStorage.getItem('access_token'),
					'Content-Type': 'multipart/form-data'
				},
				options: regionData,
				selectedOptions: [],
				params: {
					page: 1,
					'per-page': 15,
					num:12
				},
				pageCount: 1,
				provinceName: '',
				province: [], //省
				provinceId: '',
				city: [], //市
				cityId: '',
				cityName: '',
				area: [], //区
				areaId: '',
				countyName: '',
				detailInfo: '',
				is_default: 0,
				userName: '',
				telNumber: '',
				orderProduct: [], // 一个订单包含可评价的商品
				addressDetail: {
					address_name: "",
					cityName: "",
					city_id: '',
					countyName: "",
					detailInfo: "",
					id: '',
					is_default: 0,
					provinceName: "",
					province_id: '',
					region_id: '',
					status: 1,
					telNumber: "",
					userName: ""
				}, //地址详情
				edit_address: false

			};
		},
		created() {
			this.left_menu_index = this.$route.query && this.$route.query.index ? this.$route.query.index : '1-1';
			this.loading = true;
			if (this.left_menu_index == '2-1') {
				this.type = 'order';
				this.getOrder();
			} else {
				this.type = 'my';
				this.getUser();
			}
		},
		mounted() {

		},
		filters: {
			//
			toInt(val) {
				return helper.toInt(val);
			},
			// 时间格式化
			time(val) {
				return moment(val * 1000).format('YYYY-MM-DD HH:mm');
			},
		},
		watch: {
			$route(to, from) {
				if (to.query.index != from.query.index) {
					this.left_menu_index = to.query.index;
					if (this.left_menu_index == '2-1') {
						this.type = 'order';
						this.getOrder();
					} else {
						this.type = 'my';
						this.getUser();
					}
				}
			}
		},
		methods: {
			//
			getUser() {
				var param = {};
				var that = this;
				user.info(param).then(data => {
					that.loading = false;
					that.info = data.item;
				}).catch(() => {
					that.loading = false;
				});
			},
			getAddress() {
				var param = {
					page: 1,
					'per-page': 30,
					num:30
				};
				var that = this;
				user.addressList(param).then(data => {
					that.addressList = data.items;
					that.loading = false;
				}).catch(() => {
					that.loading = false;
				})
			},
			//获取省市区
			getRegion(id, type) {
				//type 1省 2市 3区
				var param = {
					page: 1,
					'per-page': 30,
					id: id,
					num:30
				};
				var that = this;
				address.region(param).then(data => {
					if (type == 1) {
						that.province = data.items
					} else {
						if (type == 2) {
							that.city = data.items
						} else {
							that.area = data.items
						}
					}
				}).catch((res) => {
					that.$message.error(res.msg ? res.msg : '获取省市区失败！')
				})
			},
			//
			changeProvince(e) {
				console.log(e)
				console.log(this.edit_address)
				this.provinceName = this.province[e].name;
				this.provinceId = this.province[e].id;

				//编辑地址时，如果更改了省，市、区清空重选
				if (this.edit_address) {
					this.addressDetail.provinceName = this.province[e].name; //省
					this.addressDetail.province_id = this.province[e].id; //省
					this.addressDetail.cityName = "";
					this.cityName='';
					this.addressDetail.city_id = '';
					this.addressDetail.countyName = "";
					this.countyName='';
					this.addressDetail.region_id = '';
				} else {
					this.cityName = '';
					this.countyName = ''
				}
				this.getRegion(this.province[e].id, 2)
			},
			changeCity(e) {
				this.cityName = this.city[e].name;
				this.cityId = this.city[e].id;

				//编辑地址时，如果更改了市，区清空重选
				if (this.edit_address) {
					this.addressDetail.cityName = this.city[e].name; //市
					this.addressDetail.city_id = this.city[e].id; //市
					this.addressDetail.countyName = "";
					this.addressDetail.region_id = '';
					this.countyName='';
				} else {
					this.countyName = ''
				}
				this.getRegion(this.city[e].id, 3)
			},
			changeArea(e) {
				this.countyName = this.area[e].name;
				this.areaId = this.area[e].id;
				if (this.edit_address) {
					this.addressDetail.countyName = this.area[e].name; //区
					this.addressDetail.region_id = this.area[e].id; //区
				};
			},
			//
			changeDefaule(e) {
				this.is_default = e;
			},
			//编辑地址
			editAddress(e) {
				this.addressDetail = e;
				this.activeAddress = 'edit_address';
				this.edit_address = true;
				//根据原本地址信息带出省市区信息
				this.getRegion(1, 1);
				this.getRegion(e.province_id, 2);
				this.getRegion(e.city_id, 3);
			},
			//
			save() {
				var that = this;
				var param = {
					avatar: this.info.avatar ? this.info.avatar : this.info.avatarUrl,
					edit: 1,
					sex: 0,
					username: this.info.username,
					mobile: this.info.mobile
				};
				user.edit(param).then(data => {
					if (data.status == 1) {
						that.$message.success('修改成功！');
					}
				}).catch((res) => {
					var msg = res.msg ? res.msg : '修改失败！'
					that.$message.error(msg);
				});
			},
			//
			setDefault(e) {
				var that = this;
				var param = {
					id: e.id
				};
				user.default(param).then(data => {
					if (data.status == 1) {
						that.$message.success('设置成功！');
						that.getAddress();
					}
				}).catch((res) => {
					var msg = res.msg ? res.msg : '设置失败！'
					that.$message.error(msg);
				});
			},
			//新增地址
			createAddress(type) {
				var that = this;
				if (type == 'add') {
					var param = {
						provinceName: this.provinceName,
						province_id: this.provinceId,
						city_id: this.cityId,
						cityName: this.countyName,
						region_id: this.areaId,
						countyName: this.countyName,
						detailInfo: this.detailInfo,
						is_default: this.is_default,
						userName: this.userName,
						telNumber: this.telNumber
					};
				} else {
					var param = {
						...this.addressDetail
					};
				}
				if (type == 'add') {
					user.create(param).then(data => {
						if (data.status == 1) {
							setTimeout(function() {
								that.activeAddress = 'address_list';
								that.getAddress();
								that.edit_address = false;
							}, 1000)
							that.$message.success('新建地址成功！');
						} else {
							that.$message.error(data.msg ? data.msg : '新建地址失败！');
						}
					}).catch(() => {

					});
				} else {
					user.submitAddress(param).then(data => {
						if (data.status == 1) {
							setTimeout(function() {
								that.activeAddress = 'address_list';
								that.edit_address = false;
								that.getAddress();
							}, 1000)
							that.$message.success('编辑成功！');
						} else {
							that.$message.error(data.msg ? data.msg : '编辑失败！');
						}
					}).catch(() => {

					})
				}
			},
			//删除地址
			delAddress(e, scope) {
				var param = {
					id: e.id
				};
				var that = this;
				var index = scope.$index;
				this.$confirm('您确定要删除该地址吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					user.delAddress(param).then(data => {
						if (data.status == 1) {
							that.$message.success('删除成功!');
							that.addressList.splice(index, 1);
						} else {
							var msg = data.msg ? data.msg : '删除失败！'
							that.$message.error(msg);
						}
					}).catch((res) => {

					});
				}).catch(() => {
					that.$message.error('已取消删除~');
				});
			},
			getCoupon() {
				var that = this;
				var param = {
					coupon_status: this.couponIndex,
					number: 10,
					page: 1,
				};
				user.coupon(param).then(data => {
					that.couponList = data.items;
					that.loading = false;
				}).catch(() => {
					that.loading = false;
				})
			},
			//我的订单
			getOrder() {
				var that = this;
				var param = {
					expand: 'orderSku,shop',
					page: this.params.page,
					status: this.orderNav[this.currentOrderIndex].status
				};
				user.all(param).then(data => {
					that.orderList = data.items;
					that.pageCount = data._meta.pageCount;
					that.loading = false;
				}).catch(() => {
					that.loading = false;
				})
			},
			//currentPage 改变时会触发,改变页码
			currentChange(currentPage) {
				// 当前行变更时的处理逻辑
				this.params.page = currentPage;
				this.orderList = []; //请求下一页前先情况上一页数据
				this.getOrder();
			},
			getRefund() {

			},
			//退出登录
			toOutLogin() {
				var that=this;
				this.loading = false;
				this.$confirm('确定要退出登录吗？', '退出登录提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					that.$mStore.state.hasLogin = false;
					that.$mStore.state.cartNum = 0;
					that.outTip();
				}).catch(() => {

				});
			},
			//
			outTip() {
				var that = this;
				this.$confirm('已退出登录！', '提示', {
					confirmButtonText: '返回首页',
					cancelButtonText: '去登录',
					type: 'success'
				}).then(() => {
					that.$router.push({
						path: '/indexMain',
						query: {}
					}).catch(() => {

					});
				}).catch(() => {
					that.$router.push({
						path: '/login',
						query: {}
					}).catch(() => {
					
					});
				});
			},
			toEvaluate(order_id, shop_id) {
				var that = this;
				var param = {
					order_id: order_id
				};
				evaluate.productList(param).then(data => {
					that.orderProduct = data.item;
					localStorage.setItem('evalArr', JSON.stringify(data.item));
				}).catch(() => {
					that.loading = false;
				})
			},
			//评价具体的商品
			confirmEvaluate(id, shop_id) {
				this.$router.push({
					path: '/evaluate',
					query: {
						id: id,
						shop_id: shop_id
					}
				}).catch(() => {

				});
			},
			//
			toUse(item) {
				if (item.product_limiter == 1) {
					this.$router.push({
						path: '/prodetail',
						query: {
							id: item.product_limiter_id
						}
					}).catch(() => {

					});
				} else {
					this.$router.push({
						path: '/indexMain',
						query: {}
					}).catch(() => {

					});
				}
			},
			// 图片上传成功的操作
			handleAvatarSuccess(res, file) {
				if (res.msgCode === 200) {
					this.info.avatar = URL.createObjectURL(file.raw)
				} else {
					this.$message.error(res.msgContent)
				}
			},
			// 图片上传前的判断
			beforeAvatarUpload(file) {
				let imgType = ['jpg', 'jpeg', 'png']
				let judge = false // 后缀
				let type = file.name.split('.')[file.name.split('.').length - 1]
				for (let k = 0; k < imgType.length; k++) {
					if (imgType[k].toUpperCase() === type.toUpperCase()) {
						judge = true
						break
					}
				}
				// 验证图片格式
				if (!judge) {
					this.$message.error('图片格式只支持：JPG、JPEG、PNG')
					return false
				}
				const isLt1M = file.size / 1024 / 1024
				if (isLt1M > 1) {
					this.$message.error('上传头像图片大小不能超过1MB')
					return false
				}
				return true
			},
			handleAddress(e) {
				if (e.index == 1) {
					this.edit_address = false;
					this.getRegion(1, 1);
				} else {
					if (e.index == 0) {
						this.edit_address = false;
						this.getAddress();
					} else {
						this.edit_address = true;
					}
				}
			},
			handleChange(e) {
				console.log(e)
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			//切换导航项
			changeOrderItem(tab, event) {
				this.currentOrderIndex = tab.index;
				this.orderList = [];
				this.loading = true;
				this.getOrder();
			},
			//
			selectCoupon(e) {
				this.couponIndex = e.index;
				this.couponList = [];
				this.loading = true;
				this.getCoupon();
			},
			//展开左侧子菜单
			openChild(e) {
				this.type = e;
				this.loading = true;
				/* 重置页码数据 */
				this.pageCount=1;
				this.params.page=1;
				switch (e) {
					case 'my':
						this.getUser();
						break;
					case 'address':
						this.getAddress();
						break;
					case 'coupon':
						this.getCoupon();
						break;
					case 'order':
						this.getOrder();
						break;
					case 'refund':
						this.getRefund();
						break;
					case 'out-login':
						this.toOutLogin();
						break;
					default:
						break;
				};
			},
			// 取消订单
			_cancelOrder(id, index) {
				var that = this;
				MessageBox.confirm('订单还未付款，确定要取消么？', '提示', {
					confirmButtonText: '取消订单',
					cancelButtonText: '考虑一下',
					type: 'warning'
				}).then(() => {
					//取消订单
					var param = {
						order_id: id,
					};
					user.cancelOrder(param).then(res => {
						if (res.status == 1) {
							that.$message.success("取消订单成功！");
							that.orderList[index].status = 8;
						} else {
							var msg = res.msg ? res.msg : '取消订单失败！'
							that.$message.error(msg);
						}
					}).catch(() => {

					})
				}).catch(() => {
					//考虑一下
				});
			},
			//
			toOrderDetail(id) {
				this.$router.push({
					path: '/orderdetail',
					query: {
						id: id
					}
				});
			},
			//
			toLogic(id) {
				this.$router.push({
					path: '/logistics',
					query: {
						id: id
					}
				}).catch(() => {

				});
			},
			// 申请退款
			toRefundOrder(id) {
				this.$router.push({
					path: '/refund',
					query: {
						id: id
					}
				}).catch(() => {

				});
			},
			//去支付
			toPay(id) {
				this.$router.push({
					path: '/payment',
					query: {
						id: id
					}
				}).catch(() => {

				});
			},
			// 确认收货
			_confirmOrder(id) {
				var that = this;
				var id = id;
				MessageBox.confirm('你是否收到该订单商品？', '提示', {
					confirmButtonText: '已收货',
					cancelButtonText: '未收货',
					type: 'warning'
				}).then(() => {
					var param = {
						order_id: id
					}
					user.confirmOrder(param).then(res => {
						if (res.status == 1) {
							that.$message.success('确认收货成功!');
							//待评价
							that.currentOrderIndex = 4;
							that.orderList = [];
							that.getOrder();
						} else {
							that.$message.error('系统繁忙，请重试一下~!');
						}
					}).catch(() => {

					})
				}).catch(() => {

				})
			}
		}
	}
</script>
<style scoped>
	@import '../styles/order.css';

	.my-order-thead {
		margin-bottom: 20px !important;
	}

	.my-order {
		padding-left: 10%;
		padding-right: 10%;
	}

	.el-pagination {
		margin-top: 20px;
	}

	.el-container {
		background-color: #f9f9f9;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.el-menu {
		border-right: solid 1px #e6e6e6;
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		background-color: #FFF;
	}

	.el-menu {
		border: none !important;
	}

	.el-main {
		background-color: #fff;
		margin-left: 20px;
	}

	.evaluate-box .el-button {
		border: none !important;
		font-size: 12px;
		height: 20px;
		line-height: 20px;
		padding: 0 !important;
	}

	.evaluate-box .el-button:hover {
		background-color: #fff !important;
		color: #ff0000 !important;
	}

	.evaluate-item {
		display: flex;
		justify-content: space-between;
		margin: 10px 0;
	}

	.evaluate-goods {
		display: flex;
		width: calc(100% - 90px);
	}

	.evaluate-goods img {
		width: 50px;
		height: 50px;
	}

	.good-name {
		width: calc(100% - 50px);
		padding: 0 20px;
		font-size: 12px;
		height: 50px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-bottom: 10rpx;
	}

	.product-name {
		max-height: 40px;
		line-height: 20px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-bottom: 10px;
		font-size: 12px;
	}

	.product-sku {
		height: 30px;
		line-height: 30px;
		color: #999 !important;
		font-size: 12px;
		text-align: left;
		padding-left: 10px
	}

	.evaluate-btn {
		border: 1px solid #ff6600;
		color: #ff6600;
		height: 20px;
		width: 90px;
		text-align: center;
		cursor: pointer;
	}

	.had-eval {
		border: 1px solid #e5e5ee !important;
		color: #999 !important;
		height: 20px;
		padding: 0 5px;
	}
</style>