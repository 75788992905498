import request from '@/utils/request'
class Evaluate {
    //提交评论信息
    add(params) {
        return request({
            url: '/product-comment/add',
            method: 'post',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
	//订单待评价的商品列表
	productList(params) {
	    return request({
	        url: '/order/getskus',
	        method: 'post',
	        data: params,
	        headers: {
	            'Content-Type': 'application/json;charset=UTF-8'
	        }
	    })
	}
	//获取某个商品评论列表
	getByGoods(param, callback) {
		return request({
		    url: 'product/comment?expand=member,replys&per-page=' + param.per_page,
		    method: 'post',
		    data: params,
		    headers: {
		        'Content-Type': 'application/json;charset=UTF-8'
		    }
		})
	}
    
};
export {
    Evaluate
};