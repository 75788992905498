import request from '@/utils/request'
class Address {
    //地区接口
    region(params) {
        return request({
            url: '/region/list',
            method: 'post',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
    
};
export {
    Address
};