import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import HelloHello from '@/components/HelloHello'
import MyTable from '@/components/MyTable'
import IndexMain from '@/components/indexMain'
import ProductDetail from '@/components/ProductDetai'
import Register from '@/components/Register'
import MyOrder from '@/components/MyOrder'
import Logistics from '@/components/Logistics'
import Cart from '@/components/Cart'
import AllProduct from '@/components/AllProduct'
import About from '@/components/About'
import News from '@/components/News'
import Recommend from '@/components/Recommend'
import indexRouter from './modules/index'
import NewsDetail from '@/components/NewsDetail'
import OrderDetail from '@/components/OrderDetail'
import Refund from '@/components/Refund'
import Confirm from '@/components/Confirm'
import NewProduct from '@/components/NewProduct'
import PayMent from '@/components/PayMent'
import Login from '@/components/Login'
import Evaluate from '@/components/Evaluate'
import CouponList from '@/components/CouponList'
import Txt from '@/components/Txt'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'indexMain',
	  title:'首页',
      component:IndexMain,
      // 到HelloWorld页面后直接跳转到indexmain页面
      // redirect: '/indexMain',
      meta: {
        authentication: true
      }
      //
    },
    {
      path: '/helloHello',
      name: 'HelloHello',
	  title:'首页',
      component: HelloHello
    },
    {
      path: '/mytable',
      name: 'MyTable',
	  title:'表格',
      component: MyTable
    },
    {
      path: '/indexmain',
      name: 'IndexMain',
	  title:'首页',
      component: IndexMain
    },
    {
      // 商品详情页
      path: '/prodetail',
      name: 'ProductDetail',
	  title:'商品详情',
      component: ProductDetail
    },
    {
      path: '/register',
      name: 'Register',
	  title:'注册',
      component: Register
    },
    {
      path: '/myorder',
      name: 'MyOrder',
	  title:'个人中心',
      component: MyOrder
    },
    {
      path: '/logistics',
      name: 'Logistics',
	  title:'物流跟踪',
      component: Logistics
    },
    {
      path: '/cart',
      name: 'Cart',
	  title:'购物车',
      component: Cart
    },
    {
      path: '/allproduct',
      name: 'AllProduct',
	  title:'全部商品',
      component: AllProduct
    },
    {
      path: '/about',
      name: 'About',
	  title:'关于我们',
      component: About
    },
    {
      path: '/news',
      name: 'News',
	  title:'新闻中心',
      component: News
    },
    {
      path: '/recommend',
      name: 'Recommend',
	  title:'推荐商品',
      component: Recommend
    },
    {
      path: '/newsdetail',
      name: 'NewsDetail',
	  title:'新闻详情',
      component: NewsDetail
    },
    {
      path: '/orderdetail',
      name: 'OrderDetail',
	  title:'订单详情',
      component: OrderDetail
    },
    {
      path: '/refund',
      name: 'Refund',
	  title:'售后',
      component: Refund
    },
    {
      path: '/confirm',
      name: 'Confirm',
	  title:'确认订单',
      component: Confirm
    },
    {
      path: '/newproduct',
      name: 'NewProduct',
	  title:'新品上市',
      component: NewProduct
    },
    {
      path: '/payment',
      name: 'PayMent',
	  title:'支付',
      component: PayMent
    },
    {
      path: '/login',
      name: 'Login',
	  title:'登录',
      component: Login
    },
	{
	  path: '/evaluate',
	  name: 'Evaluate',
	  title:'评价',
	  component: Evaluate
	},
	{
	  path: '/couponlist',
	  name: 'CouponList',
	  title:'优惠专区',
	  component: CouponList
	},
	{
	  path: '/txt',
	  name: 'Txt',
	  title:'常见问题',
	  component: Txt
	}
  ]
})
