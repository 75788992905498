import mRouter from '@/router';
import mStore from '@/store';
import { Config } from '@/config';
//常用方法集合
export default {
	
	/**
	 * 去掉整数后面的小数点
	*/
	toInt(value){
		if(value){
			value = parseFloat(value);
			value = value.toFixed(2);
		}
	    if (value == parseInt(value)){
	        return parseInt(value)
	    }else if (value == parseFloat(value)){
	    	return parseFloat(value)
	    }
	    else{
	        return value
	    }
	}
	
};

