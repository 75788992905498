<template>
    <div>
        <el-row class="container">
            <el-col class="" :xs="24" :sm="24" :md='24' :lg="24" :xl="24">
                <div v-for="item in detail">
                    <div class="title-lable">{{ item.title || '' }}</div>
                    <div class="desc">
                        <div v-html="item.content"></div>
                    </div>
                    
                </div>
                
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { News} from '@/api/news';
var news = new News(); 
import { Adv } from '@/api/adv';
var adv = new Adv();
import moment from '@/utils/moment';
import helper from '@/utils/helper';
export default {
    data() {
        return {
            activeIndex: 0,
            detail:[],
            fuwu:[],
            productIntroduction:[], //资质证书
			banner:[],
			type:''
        }
    },
	filters: {
		//
		toInt(val) {
			return helper.toInt(val);
		},
	    // 时间格式化
	    time(val) {
	        return moment(val * 1000).format('YYYY-MM-DD HH:mm');
	    },
		
	},
    created(){
		this.type=this.$route&&this.$route.query&&this.$route.query.type ? this.$route.query.type : this.type;
        this.getIndex();
    },
	watch: {
		$route(to, from) {
			if (to.query.type != from.query.type) {
				this.type=to.query.type;
				this.getIndex();
			}
		}
	},
    methods: {
        //
        getIndex(){
            var param={
                key:this.type
            };
            var that=this;
            news.pageIndex(param).then(data=>{
                that.detail=data.items;
            }).catch(()=>{

            });
        },
        select(index) {
            this.activeIndex = index;
        }
    }
}
</script>
<style scoped>
@import '../styles/about.css';
@import '../styles/timeline.css';
.title-lable{
	text-align:center;
	border: none !important;
}
</style>