import { render, staticRenderFns } from "./PayMent.vue?vue&type=template&id=6914649a&scoped=true"
import script from "./PayMent.vue?vue&type=script&lang=js"
export * from "./PayMent.vue?vue&type=script&lang=js"
import style0 from "./PayMent.vue?vue&type=style&index=0&id=6914649a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6914649a",
  null
  
)

export default component.exports