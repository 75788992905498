import request from '@/utils/request'
class News {
    //新闻列表
    newsList(params) {
        return request({
            url: '/article/index',
            method: 'get',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
    //新闻详情
    detail(params) {
        return request({
            url: '/article/view',
            method: 'get',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
    //公司简介
    pageIndex(params) {
        return request({
            url: '/page/index',
            method: 'get',
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    }
};
export {
    News
};