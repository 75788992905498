import request from '@/utils/request'
import { Config } from '../../src/config';
class Cart {
	//购物车添加
	add(param) {
		return request({
			url: '/cart/add',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//购物车删除
	delete(param) {
		return request({
			url: '/cart/delete',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//我的购物车列表
	list(param) {
		return request({
			url: '/cart/index',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
	}
	//勾选后更新总价
	getcalculatorTototal(param) {
		return request({
			url: '/cart/calculator',
			method: 'post',
			data: param,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		});
	}
};

export {
	Cart
};
