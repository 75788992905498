import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import VueClipboard from 'vue-clipboard2'
Vue.use( VueClipboard )

import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';

import BootstrapVue from 'bootstrap-vue';
import mainCss from './styles/main.css';
import '@/assets/iconfont/iconfont.css'
//success
import {
	Message
} from 'element-ui'
Vue.use(ElementUI)
Vue.prototype.$mStore = store; 
Vue.prototype.$message.success = function (msg) {
	return Message({
		message: msg,
		duration: 1500,
		type: 'success'
	})
};
Vue.prototype.$message.error = function (msg) {
	return Message({
		message: msg,
		duration: 1500,
		type: 'error'
	})
};
import 'element-ui/lib/theme-chalk/index.css';
// 配置信息
import config from '@/config'
window.BNConfig = config
Vue.prototype.BNConfig = config

Vue.use(BootstrapVue);
Vue.use(router);
Vue.config.productionTip = false;
// import cache from '@/utils/cache'
// cache.loadingCache();

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')